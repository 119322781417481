import {css} from 'lit';
export const styles = css`:host {
  display: inline-block;
  width: min-content;
  min-height: 0;
  aspect-ratio: 1 / 1;
}

[hidden] {
  display: none !important;
}

svg {
  overflow: hidden;
  width: var(--pf-c-spinner--Width,
    var(--pf-c-spinner--diameter,
      var(--pf-global--icon--FontSize--xl, 3.375rem)));
  height: var(--pf-c-spinner--Height,
    var(--pf-c-spinner--diameter,
      var(--pf-global--icon--FontSize--xl, 3.375rem)));
  animation:
    pf-c-spinner-animation-rotate
    calc(var(--pf-c-spinner--AnimationDuration, 1.4s) * 2)
    var(--pf-c-spinner--AnimationTimingFunction, linear) infinite;
}

circle {
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke: var(--pf-c-spinner--Color, var(--pf-global--primary-color--100, #06c));
  stroke-width: var(--pf-c-spinner--stroke-width, 10);
  animation:
    pf-c-spinner-animation-dash
    var(--pf-c-spinner--AnimationDuration, 1.4s)
    var(--pf-c-spinner__path--AnimationTimingFunction, ease-in-out) infinite;
}

:host([size="sm"]) svg {
  --pf-c-spinner--diameter: var(--pf-c-spinner--m-sm--diameter,
    var(--pf-global--icon--FontSize--sm, 0.625rem));
}

:host([size="md"]) svg {
  --pf-c-spinner--diameter: var(--pf-c-spinner--m-md--diameter,
    var(--pf-global--icon--FontSize--md, 1.125rem));
}

:host([size="lg"]) svg {
  --pf-c-spinner--diameter: var(--pf-c-spinner--m-lg--diameter,
    var(--pf-global--icon--FontSize--lg, 1.5rem));
}

:host([size="xl"]) svg {
  --pf-c-spinner--diameter: var(--pf-c-spinner--m-xl--diameter,
    var(--pf-global--icon--FontSize--xl, 3.375rem));
}

@keyframes pf-c-spinner-animation-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pf-c-spinner-animation-dash {
  0% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }
  15% {
    stroke-width: calc(var(--pf-c-spinner__path--StrokeWidth, 10) - 4);
  }
  40% {
    stroke-dashoffset: 150;
    stroke-dasharray: 220;
  }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(720deg);
  }
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  position: relative;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  border-radius: var(--pf-c-badge--BorderRadius,
    var(--pf-global--BorderRadius--lg, 180em));
  min-width: var(--pf-c-badge--MinWidth,
    var(--pf-global--spacer--xl, 2rem));
  padding-left: var(--pf-c-badge--PaddingLeft,
    var(--pf-global--spacer--sm, 0.5rem));
  padding-right: var(--pf-c-badge--PaddingRight,
    var(--pf-global--spacer--sm, 0.5rem));
  font-size: var(--pf-c-badge--FontSize,
    var(--pf-theme--font-size, 0.75em));
  font-weight: var(--pf-c-badge--FontWeight,
    var(--pf-theme--font-weight--bold, 700));
  line-height: var(--pf-c-badge--LineHeight,
    var(--pf-global--LineHeight--md, 1.5));
  color: var(--pf-c-badge--Color,
    var(--pf-global--palette--black-900, #151515));
  background-color: var(--pf-c-badge--BackgroundColor,
    var(--pf-global--palette--black-200, #f0f0f0));
}

:host([state="read"]) {
  --pf-c-badge--Color: var(--pf-c-badge--m-read--Color,
    var(--pf-global--palette--black-900, #151515));
  --pf-c-badge--BackgroundColor: var(--pf-c-badge--m-read--BackgroundColor,
    var(--pf-global--palette--black-200, #f0f0f0));
}

:host([state="unread"]) {
  --pf-c-badge--Color: var(--pf-c-badge--m-unread--Color,
    var(--pf-global--palette--white, #fff));
  --pf-c-badge--BackgroundColor: var(--pf-c-badge--m-unread--BackgroundColor,
    var(--pf-global--palette--blue-400, #06c));
}
`;
export default styles;

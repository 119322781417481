import {css} from 'lit';
export const styles = css`:host {
  display: inline-block;
  position: relative;
  --pf-c-dropdown__toggle--PaddingTop: var(--pf-global--spacer--form-element, 0.375rem);
  --pf-c-dropdown__toggle--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--PaddingBottom: var(--pf-global--spacer--form-element, 0.375rem);
  --pf-c-dropdown__toggle--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--MinWidth: 0;
  --pf-c-dropdown__toggle--FontSize: var(--pf-global--FontSize--md, 1rem);
  --pf-c-dropdown__toggle--FontWeight: var(--pf-global--FontWeight--normal, 400);
  --pf-c-dropdown__toggle--Color: var(--pf-global--Color--100, #151515);
  --pf-c-dropdown__toggle--LineHeight: var(--pf-global--LineHeight--md, 1.5);
  --pf-c-dropdown__toggle--BackgroundColor: transparent;
  --pf-c-dropdown__toggle--before--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
  --pf-c-dropdown__toggle--before--BorderTopColor: var(--pf-global--BorderColor--300, #f0f0f0);
  --pf-c-dropdown__toggle--before--BorderRightColor: var(--pf-global--BorderColor--300, #f0f0f0);
  --pf-c-dropdown__toggle--before--BorderBottomColor: var(--pf-global--BorderColor--200, #8a8d90);
  --pf-c-dropdown__toggle--before--BorderLeftColor: var(--pf-global--BorderColor--300, #f0f0f0);
  --pf-c-dropdown__toggle--hover--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
  --pf-c-dropdown__toggle--focus--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--focus--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
  --pf-c-dropdown__toggle--active--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--active--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
  --pf-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown--m-expanded__toggle--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
  --pf-c-dropdown__toggle--disabled--BackgroundColor: var(--pf-global--disabled-color--300, #f0f0f0);
  --pf-c-dropdown__toggle--m-plain--Color: var(--pf-global--Color--200, #6a6e73);
  --pf-c-dropdown__toggle--m-plain--hover--Color: var(--pf-global--Color--100, #151515);
  --pf-c-dropdown__toggle--m-plain--disabled--Color: var(--pf-global--disabled-color--200, #d2d2d2);
  --pf-c-dropdown__toggle--m-plain--PaddingRight: var(--pf-global--spacer--md, 1rem);
  --pf-c-dropdown__toggle--m-plain--PaddingLeft: var(--pf-global--spacer--md, 1rem);
  --pf-c-dropdown__toggle--m-plain--child--LineHeight: normal;
  --pf-c-dropdown__toggle--m-primary--Color: var(--pf-global--Color--light-100, #fff);
  --pf-c-dropdown__toggle--m-primary--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
  --pf-c-dropdown__toggle--m-primary--BackgroundColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-primary--hover--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-primary--focus--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-primary--active--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown--m-expanded__toggle--m-primary--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-primary--disabled--Color: var(--pf-global--Color--100, #151515);
  --pf-c-dropdown__toggle--m-secondary--Color: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-secondary--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
  --pf-c-dropdown__toggle--m-secondary--BackgroundColor: transparent;
  --pf-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
  --pf-c-dropdown__toggle--m-secondary--hover--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--focus--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--active--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--before--BorderColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-secondary--hover--before--BorderColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-secondary--focus--before--BorderColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-secondary--active--before--BorderColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown--m-expanded__toggle--m-secondary--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown--m-expanded__toggle--m-secondary--before--BorderColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle-button--Color: var(--pf-global--Color--100, #151515);
  --pf-c-dropdown__toggle-progress--Visibility: hidden;
  --pf-c-dropdown__toggle-progress--c-spinner--diameter: var(--pf-global--FontSize--sm, 0.875rem);
  --pf-c-dropdown__toggle--m-split-button--child--PaddingTop: var(--pf-global--spacer--form-element, 0.375rem);
  --pf-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-global--spacer--xs, 0.25rem);
  --pf-c-dropdown__toggle--m-split-button--child--PaddingBottom: var(--pf-global--spacer--form-element, 0.375rem);
  --pf-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-global--spacer--xs, 0.25rem);
  --pf-c-dropdown__toggle--m-split-button--child--BackgroundColor: transparent;
  --pf-c-dropdown__toggle--m-split-button--first-child--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--m-split-button--last-child--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--m-split-button--m-action--child--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--m-split-button--m-action--child--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight: calc(-1 * var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-dropdown__toggle--m-split-button__toggle-check__input--TranslateY: -0.0625rem;
  --pf-c-dropdown__toggle--m-split-button__toggle-text--MarginLeft: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--m-split-button--child--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
  --pf-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius: var(--pf-c-dropdown__toggle--m-split-button--child--BorderRadius);
  --pf-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-split-button--m-primary--child--hover--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-split-button--m-primary--child--focus--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-split-button--m-primary--child--active--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-split-button--m-primary--child--m-expanded--BackgroundColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftColor: var(--pf-global--primary-color--200, #004080);
  --pf-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth: var(--pf-global--BorderWidth--sm, 1px);
  --pf-c-dropdown--m-expanded__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--Color: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--hover--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--focus--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--active--before--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base: var(--pf-global--BorderWidth--sm, 1px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base: var(--pf-global--primary-color--100, #06c);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor: var(--pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--hover--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--focus--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle--m-secondary--m-split-button--child--before--active--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
  --pf-c-dropdown__toggle-icon--LineHeight: var(--pf-global--LineHeight--md, 1.5);
  --pf-c-dropdown__toggle-icon--MarginRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle-icon--MarginLeft: var(--pf-global--spacer--md, 1rem);
  --pf-c-dropdown--m-top--m-expanded__toggle-icon--Rotate: 180deg;
  --pf-c-dropdown--m-plain__toggle-icon--Color: var(--pf-global--Color--200, #6a6e73);
  --pf-c-dropdown--m-plain--hover__toggle-icon--Color: var(--pf-global--Color--100, #151515);
  --pf-c-dropdown__menu--BackgroundColor: var(--pf-global--BackgroundColor--light-100, #fff);
  --pf-c-dropdown__menu--BoxShadow: var(--pf-global--BoxShadow--md, 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06));
  --pf-c-dropdown__menu--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__menu--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__menu--Top: calc(100% + var(--pf-global--spacer--xs, 0.25rem));
  --pf-c-dropdown__menu--ZIndex: var(--pf-global--ZIndex--sm, 200);
  --pf-c-dropdown--m-top__menu--Top: 0;
  --pf-c-dropdown--m-top__menu--TranslateY: calc(-100% - var(--pf-global--spacer--xs, 0.25rem));
  --pf-c-dropdown__menu-item--BackgroundColor: transparent;
  --pf-c-dropdown__menu-item--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__menu-item--PaddingRight: var(--pf-global--spacer--md, 1rem);
  --pf-c-dropdown__menu-item--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__menu-item--PaddingLeft: var(--pf-global--spacer--md, 1rem);
  --pf-c-dropdown__menu-item--FontSize: var(--pf-global--FontSize--md, 1rem);
  --pf-c-dropdown__menu-item--FontWeight: var(--pf-global--FontWeight--normal, 400);
  --pf-c-dropdown__menu-item--LineHeight: var(--pf-global--LineHeight--md, 1.5);
  --pf-c-dropdown__menu-item--Color: var(--pf-global--Color--dark-100, #151515);
  --pf-c-dropdown__menu-item--hover--Color: var(--pf-global--Color--dark-100, #151515);
  --pf-c-dropdown__menu-item--disabled--Color: var(--pf-global--Color--dark-200, #6a6e73);
  --pf-c-dropdown__menu-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300, #f0f0f0);
  --pf-c-dropdown__menu-item--disabled--BackgroundColor: transparent;
  --pf-c-dropdown__menu-item--m-text--Color: var(--pf-global--Color--dark-200, #6a6e73);
  --pf-c-dropdown__menu-item-icon--MarginRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__menu-item-icon--Width: var(--pf-global--icon--FontSize--lg, 1.5rem);
  --pf-c-dropdown__menu-item-icon--Height: var(--pf-global--icon--FontSize--lg, 1.5rem);
  --pf-c-dropdown__menu-item-description--FontSize: var(--pf-global--FontSize--xs, 0.75rem);
  --pf-c-dropdown__menu-item-description--Color: var(--pf-global--Color--dark-200, #6a6e73);
  --pf-c-dropdown__group--group--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__group-title--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__group-title--PaddingRight: var(--pf-c-dropdown__menu-item--PaddingRight);
  --pf-c-dropdown__group-title--PaddingBottom: var(--pf-c-dropdown__menu-item--PaddingBottom);
  --pf-c-dropdown__group-title--PaddingLeft: var(--pf-c-dropdown__menu-item--PaddingLeft);
  --pf-c-dropdown__group-title--FontSize: var(--pf-global--FontSize--xs, 0.75rem);
  --pf-c-dropdown__group-title--FontWeight: var(--pf-global--FontWeight--normal, 400);
  --pf-c-dropdown__group-title--Color: var(--pf-global--Color--dark-200, #6a6e73);
  --pf-c-dropdown__toggle-image--MarginTop: 0;
  --pf-c-dropdown__toggle-image--MarginBottom: 0;
  --pf-c-dropdown__toggle-image--MarginRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown--c-divider--MarginTop: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown--c-divider--MarginBottom: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-dropdown__toggle--c-badge__toggle-icon--MarginLeft: var(--pf-global--spacer--xs, 0.25rem);
  --pf-c-dropdown__toggle--c-badge__toggle-icon--MarginRight: 0;
  --pf-c-dropdown--c-menu--Top: calc(100% + var(--pf-global--spacer--xs, 0.25rem));
  --pf-c-dropdown--c-menu--ZIndex: var(--pf-global--ZIndex--sm, 200);
  --pf-c-dropdown--m-top--c-menu--Top: 0;
  --pf-c-dropdown--m-top--c-menu--TranslateY: calc(-100% - var(--pf-global--spacer--xs, 0.25rem));
  --pf-c-dropdown--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-c-dropdown--m-full-height__toggle--expanded--before--BorderBottomWidth: var(--pf-global--BorderWidth--xl, 4px);
  --pf-c-dropdown--m-full-height__toggle--hover--before--BorderBottomWidth: var(--pf-global--BorderWidth--xl, 4px);
  --pf-c-dropdown--m-full-height__toggle--active--before--BorderBottomWidth: var(--pf-global--BorderWidth--xl, 4px);
  --pf-c-dropdown--m-full-height__toggle--focus--before--BorderBottomWidth: var(--pf-global--BorderWidth--xl, 4px);
  --pf-c-dropdown--m-full-height__toggle--PaddingRight: var(--pf-global--spacer--lg, 1.5rem);
  --pf-c-dropdown--m-full-height__toggle--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
  --_font-size: var(
    --pf-c-dropdown__menu-item--FontSize,
    var(--pf-global--FontSize--md, 1rem)
  );
  --_font-weight: var(
    --pf-c-dropdown__menu-item--FontWeight,
    var(--pf-global--FontWeight--normal, 400)
  );
  --_line-height: var(
    --pf-c-dropdown__menu-item--LineHeight,
    var(--pf-global--LineHeight--md, 1.5)
  );
  --_color: var(
    --pf-c-dropdown__menu-item--Color,
    var(--pf-global--Color--dark-100, #151515)
  );
  --_background-color: var(
    --pf-c-dropdown__menu-item--BackgroundColor,
    transparent
  );
}

:host([disabled]) {
  color: var(--pf-global--Color--dark-200, #6a6e73);
}

:host([hidden]),
[hidden] {
  display: none !important;
}

slot[name="toggle"] {
  cursor: pointer;
}

pf-button#default-toggle,
::slotted([slot="toggle"]) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: var(--pf-c-dropdown__toggle--MinWidth);
  max-width: 100%;
  padding:
    var(--pf-c-dropdown__toggle--PaddingTop)
    var(--pf-c-dropdown__toggle--PaddingRight)
    var(--pf-c-dropdown__toggle--PaddingBottom)
    var(--pf-c-dropdown__toggle--PaddingLeft);
  font-size: var(--pf-c-dropdown__toggle--FontSize);
  font-weight: var(--pf-c-dropdown__toggle--FontWeight);
  line-height: var(--pf-c-dropdown__toggle--LineHeight);
  color: var(--pf-c-dropdown__toggle--Color);
  background-color: var(--pf-c-dropdown__toggle--BackgroundColor);
  border: none;
}

pf-dropdown-menu,
::slotted(pf-dropdown-menu) {
  position: absolute !important;
  top: var(
    --pf-c-dropdown__menu--Top,
    calc(100% + var(--pf-global--spacer--xs, 0.25rem))
  ) !important;
  left: 0 !important;
  z-index: var(
    --pf-c-dropdown__menu--ZIndex,
    var(--pf-global--ZIndex--sm, 200)
  ) !important;
  padding-top: var(
    --pf-c-dropdown__menu--PaddingTop,
    var(--pf-global--spacer--sm, 0.5rem)
  ) !important;
  padding-bottom: var(
    --pf-c-dropdown__menu--PaddingBottom,
    var(--pf-global--spacer--sm, 0.5rem)
  ) !important;
  background: var(--pf-c-dropdown__menu--BackgroundColor, #fff) !important;
  background-clip: padding-box !important;
  box-shadow: var(
    --pf-c-dropdown__menu--BoxShadow,
    var(
      --pf-global--BoxShadow--md,
      0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12),
      0 0 0.25rem 0 rgba(3, 3, 3, 0.06)
    )
  ) !important;
  min-width: 100% !important;
  margin: 0 !important;
}

pf-button svg {
  width: 1em;
  height: 1em;
  flex: 0 0 auto;
  margin-inline-start: 1em;
}

::slotted(hr) {
  margin: 0;
  border-color: var(--pf-c-divider--BackgroundColor, var(--pf-global--BorderColor--100, #d2d2d2));
  border-style: solid;
}

::slotted([role="separator"]:not(hr)) {
  width: 100%;
  height: 1px;
  background-color: var(--pf-c-divider--BackgroundColor, var(--pf-global--BorderColor--100, #d2d2d2));
  padding: 0px;
  margin: 0px;
  border: 0;
  display: block;
}

.disabled pf-dropdown-menu,.disabled ::slotted(pf-dropdown-menu) {
    background-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
    cursor: not-allowed !important;
  }

.disabled pf-button#default-toggle,.disabled ::slotted([slot="toggle"]) {
    --pf-c-button--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--after--BorderColor: var(--pf-c-button--disabled--after--BorderColor);

    --pf-c-button--m-primary--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);

    --pf-c-button--m-secondary--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-secondary--focus--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-secondary--hover--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-secondary--active--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-secondary--after--BorderColor: transparent;
    --pf-c-button--m-secondary--hover--after--BorderColor: transparent;
    --pf-c-button--m-secondary--focus--after--BorderColor: transparent;
    --pf-c-button--m-secondary--active--after--BorderColor: transparent;
    --pf-c-button--m-secondary--focus--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-secondary--hover--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-secondary--active--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-secondary--focus--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);

    --pf-c-button--m-control--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-control--focus--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-control--hover--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-control--active--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-control--after--BorderColor: transparent;
    --pf-c-button--m-control--hover--after--BorderColor: transparent;
    --pf-c-button--m-control--focus--after--BorderColor: transparent;
    --pf-c-button--m-control--active--after--BorderColor: transparent;
    --pf-c-button--m-control--focus--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-control--hover--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-control--active--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-control--focus--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-control--after--BorderBottomColor: transparent;
    --pf-c-button--m-control--focus--after--BorderBottomColor: transparent;
    --pf-c-button--m-control--hover--after--BorderBottomColor: transparent;
    --pf-c-button--m-control--active--after--BorderBottomColor: transparent;

    --pf-c-button--m-plain--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-plain--focus--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-plain--hover--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-plain--active--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-plain--after--BorderColor: transparent;
    --pf-c-button--m-plain--hover--after--BorderColor: transparent;
    --pf-c-button--m-plain--focus--after--BorderColor: transparent;
    --pf-c-button--m-plain--active--after--BorderColor: transparent;
    --pf-c-button--m-plain--focus--Color: var(--pf-c-button--disabled--Color);
    --pf-c-button--m-plain--hover--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-plain--active--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
    --pf-c-button--m-plain--focus--BackgroundColor: var(--pf-c-button--disabled--BackgroundColor);
  }

.disabled pf-button:focus,.disabled ::slotted([slot="toggle"]:focus) {
    outline: 3px solid var(--pf-global--link--Color, #0066cc);
  }

pf-button#default-toggle,
::slotted(pf-button[variant="control"]) {
  --_button-icon-vertical-align: -0.25em;
  --_button-icon-padding-inline-start: var(--pf-c-dropdown__toggle--PaddingLeft,
    var(--pf-global--spacer--md, 1rem));
  --_button-icon-padding-inline-end: var(-pf-c-dropdown__toggle--PaddingRight,
    var(--pf-global--spacer--sm, 0.5rem));
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
	--pf-c-button--PaddingTop: var(--pf-global--spacer--form-element, 0.375rem);
	--pf-c-button--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-button--PaddingBottom: var(--pf-global--spacer--form-element, 0.375rem);
	--pf-c-button--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-button--LineHeight: var(--pf-global--LineHeight--md, 1.5);
	--pf-c-button--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-button--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-button--BackgroundColor: transparent;
	--pf-c-button--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
	--pf-c-button--after--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
	--pf-c-button--after--BorderColor: transparent;
	--pf-c-button--after--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-button--hover--after--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--focus--after--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--active--after--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--disabled--Color: var(--pf-global--disabled-color--100, #6a6e73);
	--pf-c-button--disabled--BackgroundColor: var(--pf-global--disabled-color--200, #d2d2d2);
	--pf-c-button--disabled--after--BorderColor: transparent;
	--pf-c-button--m-primary--BackgroundColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-primary--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--primary-color--200, #004080);
	--pf-c-button--m-primary--hover--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--primary-color--200, #004080);
	--pf-c-button--m-primary--focus--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--primary-color--200, #004080);
	--pf-c-button--m-primary--active--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-secondary--BackgroundColor: transparent;
	--pf-c-button--m-secondary--after--BorderColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--Color: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--hover--BackgroundColor: transparent;
	--pf-c-button--m-secondary--hover--after--BorderColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--hover--Color: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--focus--BackgroundColor: transparent;
	--pf-c-button--m-secondary--focus--after--BorderColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--focus--Color: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--active--BackgroundColor: transparent;
	--pf-c-button--m-secondary--active--after--BorderColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--active--Color: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-secondary--m-danger--BackgroundColor: transparent;
	--pf-c-button--m-secondary--m-danger--Color: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-secondary--m-danger--after--BorderColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-secondary--m-danger--hover--BackgroundColor: transparent;
	--pf-c-button--m-secondary--m-danger--hover--Color: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-secondary--m-danger--hover--after--BorderColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-secondary--m-danger--focus--BackgroundColor: transparent;
	--pf-c-button--m-secondary--m-danger--focus--Color: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-secondary--m-danger--focus--after--BorderColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-secondary--m-danger--active--BackgroundColor: transparent;
	--pf-c-button--m-secondary--m-danger--active--Color: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-secondary--m-danger--active--after--BorderColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-tertiary--BackgroundColor: transparent;
	--pf-c-button--m-tertiary--after--BorderColor: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--hover--BackgroundColor: transparent;
	--pf-c-button--m-tertiary--hover--after--BorderColor: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--hover--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--focus--BackgroundColor: transparent;
	--pf-c-button--m-tertiary--focus--after--BorderColor: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--focus--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--active--BackgroundColor: transparent;
	--pf-c-button--m-tertiary--active--after--BorderColor: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-tertiary--active--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-warning--BackgroundColor: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-button--m-warning--Color: var(--pf-global--Color--dark-100, #151515);
	--pf-c-button--m-warning--hover--BackgroundColor: var(--pf-global--palette--gold-500, #c58c00);
	--pf-c-button--m-warning--hover--Color: var(--pf-global--Color--dark-100, #151515);
	--pf-c-button--m-warning--focus--BackgroundColor: var(--pf-global--palette--gold-500, #c58c00);
	--pf-c-button--m-warning--focus--Color: var(--pf-global--Color--dark-100, #151515);
	--pf-c-button--m-warning--active--BackgroundColor: var(--pf-global--palette--gold-500, #c58c00);
	--pf-c-button--m-warning--active--Color: var(--pf-global--Color--dark-100, #151515);
	--pf-c-button--m-danger--BackgroundColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-danger--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-danger--hover--BackgroundColor: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-danger--hover--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-danger--focus--BackgroundColor: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-danger--focus--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-danger--active--BackgroundColor: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-danger--active--Color: var(--pf-global--Color--light-100, #fff);
	--pf-c-button--m-link--BackgroundColor: transparent;
	--pf-c-button--m-link--Color: var(--pf-global--link--Color, #06c);
	--pf-c-button--m-link--hover--BackgroundColor: transparent;
	--pf-c-button--m-link--hover--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-button--m-link--focus--BackgroundColor: transparent;
	--pf-c-button--m-link--focus--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-button--m-link--active--BackgroundColor: transparent;
	--pf-c-button--m-link--active--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-button--m-link--disabled--BackgroundColor: transparent;
	--pf-c-button--m-link--m-inline--FontSize: inherit;
	--pf-c-button--m-link--m-inline--hover--TextDecoration: var(--pf-global--link--TextDecoration--hover, underline);
	--pf-c-button--m-link--m-inline--hover--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-button--m-link--m-inline--PaddingTop: 0;
	--pf-c-button--m-link--m-inline--PaddingRight: 0;
	--pf-c-button--m-link--m-inline--PaddingBottom: 0;
	--pf-c-button--m-link--m-inline--PaddingLeft: 0;
	--pf-c-button--m-link--m-inline__progress--Left: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-button--m-link--m-inline--m-in-progress--PaddingLeft: calc(var(--pf-c-button--m-link--m-inline__progress--Left) + 1rem + var(--pf-global--spacer--sm, 0.5rem));
	--pf-c-button--m-link--m-danger--BackgroundColor: transparent;
	--pf-c-button--m-link--m-danger--Color: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-button--m-link--m-danger--hover--BackgroundColor: transparent;
	--pf-c-button--m-link--m-danger--hover--Color: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-link--m-danger--focus--BackgroundColor: transparent;
	--pf-c-button--m-link--m-danger--focus--Color: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-link--m-danger--active--BackgroundColor: transparent;
	--pf-c-button--m-link--m-danger--active--Color: var(--pf-global--danger-color--200, #a30000);
	--pf-c-button--m-plain--BackgroundColor: transparent;
	--pf-c-button--m-plain--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-button--m-plain--hover--BackgroundColor: transparent;
	--pf-c-button--m-plain--hover--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-plain--focus--BackgroundColor: transparent;
	--pf-c-button--m-plain--focus--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-plain--active--BackgroundColor: transparent;
	--pf-c-button--m-plain--active--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-plain--disabled--Color: var(--pf-global--disabled-color--200, #d2d2d2);
	--pf-c-button--m-plain--disabled--BackgroundColor: transparent;
	--pf-c-button--m-control--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-button--m-control--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-control--BorderRadius: 0;
	--pf-c-button--m-control--after--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-button--m-control--after--BorderTopColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-button--m-control--after--BorderRightColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-button--m-control--after--BorderBottomColor: var(--pf-global--BorderColor--200, #8a8d90);
	--pf-c-button--m-control--after--BorderLeftColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-button--m-control--disabled--BackgroundColor: var(--pf-global--disabled-color--300, #f0f0f0);
	--pf-c-button--m-control--hover--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-button--m-control--hover--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-control--hover--after--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--m-control--hover--after--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-button--m-control--active--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-button--m-control--active--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-control--active--after--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--m-control--active--after--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-button--m-control--focus--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-button--m-control--focus--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-control--focus--after--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--m-control--focus--after--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-button--m-control--m-expanded--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-button--m-control--m-expanded--Color: var(--pf-global--Color--100, #151515);
	--pf-c-button--m-control--m-expanded--after--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-button--m-small--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-button--m-display-lg--PaddingTop: var(--pf-global--spacer--md, 1rem);
	--pf-c-button--m-display-lg--PaddingRight: var(--pf-global--spacer--xl, 2rem);
	--pf-c-button--m-display-lg--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-button--m-display-lg--PaddingLeft: var(--pf-global--spacer--xl, 2rem);
	--pf-c-button--m-display-lg--FontWeight: var(--pf-global--FontWeight--bold, 700);
	--pf-c-button--m-link--m-display-lg--FontSize: var(--pf-global--FontSize--lg, 1.125rem);
	--pf-c-button__icon--m-start--MarginRight: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-button__icon--m-end--MarginLeft: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-button__progress--width: calc(var(--pf-global--icon--FontSize--md, 1.125rem) + var(--pf-global--spacer--sm, 0.5rem));
	--pf-c-button__progress--Opacity: 0;
	--pf-c-button__progress--TranslateY: -50%;
	--pf-c-button__progress--TranslateX: 0;
	--pf-c-button__progress--Top: 50%;
	--pf-c-button__progress--Left: var(--pf-global--spacer--md, 1rem);
	--pf-c-button--m-progress--TransitionProperty: padding;
	--pf-c-button--m-progress--TransitionDuration: var(--pf-global--TransitionDuration, 250ms);
	--pf-c-button--m-progress--PaddingRight: calc(var(--pf-global--spacer--md, 1rem) + var(--pf-c-button__progress--width) / 2);
	--pf-c-button--m-progress--PaddingLeft: calc(var(--pf-global--spacer--md, 1rem) + var(--pf-c-button__progress--width) / 2);
	--pf-c-button--m-in-progress--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-button--m-in-progress--PaddingLeft: calc(var(--pf-global--spacer--md, 1rem) + var(--pf-c-button__progress--width));
	--pf-c-button--m-in-progress--m-plain--Color: var(--pf-global--primary-color--100, #06c);
	--pf-c-button--m-in-progress--m-plain__progress--Left: 50%;
	--pf-c-button--m-in-progress--m-plain__progress--TranslateX: -50%;
	--pf-c-button__count--MarginLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-button--disabled__c-badge--Color: var(--pf-global--Color--dark-100, #151515);
	--pf-c-button--disabled__c-badge--BackgroundColor: var(--pf-global--BackgroundColor--200, #f0f0f0);
	--pf-c-button--m-primary__c-badge--BorderColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-button--m-primary__c-badge--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);

  --_button-color: var(--pf-c-button--m-primary--Color);
  --_button-background-color: var(--pf-c-button--m-primary--BackgroundColor);
}
`;
export default styles;

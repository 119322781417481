import {css} from 'lit';
export const styles = css`:host {
	--pf-c-table--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--BorderColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-table--border-width--base: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-table-caption--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-table-caption--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-table-caption--PaddingTop: var(--pf-global--spacer--md, 1rem);
	--pf-c-table-caption--PaddingRight: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table-caption--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-table-caption--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table-caption--xl--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-table-caption--xl--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-table--thead--cell--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-table--thead--cell--FontWeight: var(--pf-global--FontWeight--bold, 700);
	--pf-c-table--tbody--cell--PaddingTop: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--tbody--cell--PaddingBottom: var(--pf-global--spacer--lg);
	--pf-c-table--tr--BoxShadow--top--base: 0 -0.1875rem 0.25rem -0.125rem rgba(3,3,3,.08);
	--pf-c-table--cell--Padding--base: var(--pf-global--spacer--md, 1rem);
	--pf-c-table--cell--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-table--cell--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-table--cell--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table--cell--PaddingTop: var(--pf-c-table--cell--Padding--base);
	--pf-c-table--cell--PaddingRight: var(--pf-c-table--cell--Padding--base);
	--pf-c-table--cell--PaddingBottom: var(--pf-c-table--cell--Padding--base);
	--pf-c-table--cell--PaddingLeft: var(--pf-c-table--cell--Padding--base);
	--pf-c-table--cell--first-last-child--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-table--cell--first-last-child--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-table--cell--first-last-child--xl--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--cell--first-last-child--xl--PaddingRight: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--tr--m-first-cell-offset-reset--cell--PaddingLeft: var(--pf-c-table--cell--Padding--base);
	--pf-c-table--cell--MinWidth: 0;
	--pf-c-table--cell--MaxWidth: none;
	--pf-c-table--cell--Width: auto;
	--pf-c-table--cell--Overflow: visible;
	--pf-c-table--cell--TextOverflow: clip;
	--pf-c-table--cell--WhiteSpace: normal;
	--pf-c-table--cell--WordBreak: normal;
	--pf-c-table--cell--m-border-right--before--BorderRightWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-table--cell--m-border-right--before--BorderRightColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-table--cell--m-border-left--before--BorderLeftWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-table--cell--m-border-left--before--BorderLeftColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-table--cell--m-help--MinWidth: 11ch;
	--pf-c-table--m-truncate--cell--MaxWidth: 1px;
	--pf-c-table--m-truncate--cell--MinWidth: calc(5ch + var(--pf-c-table--cell--PaddingRight) + var(--pf-c-table--cell--PaddingLeft));
	--pf-c-table--cell--hidden-visible--Display: table-cell;
	--pf-c-table__toggle--c-button--MarginTop: calc(0.375rem * -1);
	--pf-c-table__toggle--c-button--MarginBottom: calc(0.375rem * -1);
	--pf-c-table__toggle--c-button__toggle-icon--Rotate: 270deg;
	--pf-c-table__toggle--c-button__toggle-icon--Transition: .2s ease-in 0s;
	--pf-c-table__toggle--c-button--m-expanded__toggle-icon--Rotate: 360deg;
	--pf-c-table__button--BackgroundColor: transparent;
	--pf-c-table__button--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__button--hover--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__button--focus--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__button--active--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__button--OutlineOffset: calc(var(--pf-global--BorderWidth--lg, 3px) * -1);
	--pf-c-table--m-compact__toggle--PaddingTop: 0;
	--pf-c-table--m-compact__toggle--PaddingBottom: 0;
	--pf-c-table__check--input--MarginTop: 0.25rem;
	--pf-c-table__check--input--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-table--cell--m-favorite--Color: var(--pf-global--Color--light-300, #d2d2d2);
	--pf-c-table__favorite--c-button--Color: var(--pf-global--Color--light-300, #d2d2d2);
	--pf-c-table__favorite--c-button--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-table__favorite--c-button--MarginTop: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-table__favorite--c-button--MarginRight: calc(var(--pf-global--spacer--md, 1rem) * -1);
	--pf-c-table__favorite--c-button--MarginBottom: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-table__favorite--c-button--MarginLeft: calc(var(--pf-global--spacer--md, 1rem) * -1);
	--pf-c-table__favorite--m-favorited--c-button--Color: var(--pf-global--palette--gold-400, #f0ab00);
	--pf-c-table__sort--m-favorite__button__text--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-table__sort--m-favorite__button--hover__text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__sort--m-favorite__button--focus__text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__sort--m-favorite__button--active__text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__draggable--c-button--MarginTop: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-table__draggable--c-button--MarginRight: calc(var(--pf-global--spacer--md, 1rem) * -1);
	--pf-c-table__draggable--c-button--MarginBottom: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-table__draggable--c-button--MarginLeft: calc(var(--pf-global--spacer--md, 1rem) * -1);
	--pf-c-table__tr--m-ghost-row--Opacity: .4;
	--pf-c-table__tr--m-ghost-row--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table__action--PaddingTop: 0;
	--pf-c-table__action--PaddingRight: 0;
	--pf-c-table__action--PaddingBottom: 0;
	--pf-c-table__action--PaddingLeft: 0;
	--pf-c-table__inline-edit-action--PaddingTop: 0;
	--pf-c-table__inline-edit-action--PaddingRight: 0;
	--pf-c-table__inline-edit-action--PaddingBottom: 0;
	--pf-c-table__inline-edit-action--PaddingLeft: 0;
	--pf-c-table__expandable-row--Transition: var(--pf-global--Transition, all 250ms cubic-bezier(0.42, 0, 0.58, 1));
	--pf-c-table__expandable-row--MaxHeight: 28.125rem;
	--pf-c-table__expandable-row-content--Transition: var(--pf-global--Transition, all 250ms cubic-bezier(0.42, 0, 0.58, 1));
	--pf-c-table__expandable-row-content--PaddingTop: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table__expandable-row-content--PaddingBottom: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table__expandable-row--after--Top: calc(var(--pf-c-table--border-width--base) * -1);
	--pf-c-table__expandable-row--after--Bottom: calc(var(--pf-c-table--border-width--base) * -1);
	--pf-c-table__expandable-row--after--border-width--base: var(--pf-global--BorderWidth--lg, 3px);
	--pf-c-table__expandable-row--after--BorderLeftWidth: 0;
	--pf-c-table__expandable-row--after--BorderColor: var(--pf-global--active-color--100, #06c);
	--pf-c-table__icon-inline--MarginRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table__sort--MinWidth: calc(6ch + var(--pf-c-table--cell--PaddingRight) + var(--pf-c-table--cell--PaddingLeft) + var(--pf-c-table__sort-indicator--MarginLeft));
	--pf-c-table__sort__button--PaddingTop: var(--pf-global--spacer--form-element, 0.375rem);
	--pf-c-table__sort__button--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table__sort__button--PaddingBottom: var(--pf-global--spacer--form-element, 0.375rem);
	--pf-c-table__sort__button--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table__sort__button--MarginTop: calc(var(--pf-c-table__sort__button--PaddingTop) * -1);
	--pf-c-table__sort__button--MarginBottom: calc(var(--pf-c-table__sort__button--PaddingBottom) * -1);
	--pf-c-table__sort__button--MarginLeft: calc(var(--pf-c-table__sort__button--PaddingLeft) * -1);
	--pf-c-table__sort__button--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__sort--m-selected__button--Color: var(--pf-global--active-color--100, #06c);
	--pf-c-table__sort--m-help--MinWidth: 15ch;
	--pf-c-table__sort__button__text--Color: currentcolor;
	--pf-c-table__sort__button--hover__text--Color: currentcolor;
	--pf-c-table__sort__button--focus__text--Color: currentcolor;
	--pf-c-table__sort__button--active__text--Color: currentcolor;
	--pf-c-table__sort-indicator--Color: var(--pf-global--disabled-color--200, #d2d2d2);
	--pf-c-table__sort-indicator--MarginLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-table__sort--m-selected__sort-indicator--Color: var(--pf-global--active-color--100, #06c);
	--pf-c-table__sort__button--hover__sort-indicator--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__sort__button--active__sort-indicator--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table__sort__button--focus__sort-indicator--Color: var(--pf-global--Color--100, #151515);
	--pf-c-table--th--m-help--MinWidth: 11ch;
	--pf-c-table__column-help--MarginLeft: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-table__column-help--TranslateY: 0.125rem;
	--pf-c-table__column-help--c-button--MarginTop: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-table__column-help--c-button--MarginBottom: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-table__column-help--c-button--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table__column-help--c-button--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table__compound-expansion-toggle__button--Color: var(--pf-global--active-color--100, #06c);
	--pf-c-table__compound-expansion-toggle__button--hover--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-table__compound-expansion-toggle__button--focus--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-table__compound-expansion-toggle__button--active--Color: var(--pf-global--link--Color--hover, #004080);
	--pf-c-table__compound-expansion-toggle__button--before--border-width--base: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-table__compound-expansion-toggle__button--before--BorderColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
	--pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
	--pf-c-table__compound-expansion-toggle__button--before--Bottom: calc(var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
	--pf-c-table__compound-expansion-toggle__button--before--Left: calc(var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
	--pf-c-table__compound-expansion-toggle__button--after--border-width--base: var(--pf-global--BorderWidth--lg, 3px);
	--pf-c-table__compound-expansion-toggle__button--after--BorderColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-table__compound-expansion-toggle__button--after--BorderTopWidth: 0;
	--pf-c-table__compound-expansion-toggle__button--after--Top: calc(var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
	--pf-c-table__compound-expansion-toggle__button--after--Left: calc(var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
	--pf-c-table--m-compact-th--PaddingTop: calc(var(--pf-global--spacer--sm, 0.5rem) + var(--pf-global--spacer--xs));
	--pf-c-table--m-compact-th--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table--m-compact--cell--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table--m-compact--cell--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table--m-compact--cell--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table--m-compact--cell--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-table--m-compact--cell--first-last-child--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-table--m-compact--cell--first-last-child--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-table--m-compact--cell--first-last-child--xl--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--m-compact--cell--first-last-child--xl--PaddingRight: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--m-compact--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-table--m-compact__expandable-row-content--PaddingTop: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--m-compact__expandable-row-content--PaddingRight: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--m-compact__expandable-row-content--PaddingBottom: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--m-compact__expandable-row-content--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-table--nested--first-last-child--PaddingRight: var(--pf-c-table--m-compact--cell--first-last-child--PaddingLeft);
	--pf-c-table--nested--first-last-child--PaddingLeft: var(--pf-c-table--m-compact--cell--first-last-child--PaddingRight);
	--pf-c-table__expandable-row--m-expanded--BorderBottomColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-table--tr--m-hoverable--BoxShadow--top: var(--pf-c-table--tr--BoxShadow--top--base);
	--pf-c-table--tr--m-hoverable--BackgroundColor: transparent;
	--pf-c-table--tr--m-hoverable--BoxShadow: none;
	--pf-c-table--tr--m-hoverable--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs, 0.25rem));
	--pf-c-table--tr--m-hoverable--hover--BoxShadow: var(--pf-c-table--tr--m-hoverable--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-hoverable--hover--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tr--m-hoverable--focus--BoxShadow: var(--pf-c-table--tr--m-hoverable--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-hoverable--focus--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tr--m-hoverable--active--BoxShadow: var(--pf-c-table--tr--m-hoverable--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-hoverable--active--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tr--m-hoverable--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16)) inset, var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-selected--BoxShadow--top: var(--pf-c-table--tr--BoxShadow--top--base);
	--pf-c-table--tr--m-selected--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tr--m-selected--BoxShadow: var(--pf-c-table--tr--m-selected--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-selected--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs, 0.25rem));
	--pf-c-table--tr--m-selected--after--BorderLeftWidth: var(--pf-c-table__expandable-row--after--border-width--base);
	--pf-c-table--tr--m-selected--after--BorderLeftColor: var(--pf-global--active-color--100, #06c);
	--pf-c-table--tr--m-selected--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-selected--hover--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16)) inset, var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tr--m-selected--tr--m-selected--hover--BoxShadow: var(--pf-c-table--tr--m-selected--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-hoverable--BoxShadow--top: var(--pf-c-table--tr--BoxShadow--top--base);
	--pf-c-table--tbody--m-hoverable--BoxShadow: none;
	--pf-c-table--tbody--m-hoverable--BackgroundColor: transparent;
	--pf-c-table--tbody--m-hoverable--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs, 0.25rem));
	--pf-c-table--tbody--m-hoverable--hover--BoxShadow: var(--pf-c-table--tbody--m-hoverable--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-hoverable--hover--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tbody--m-hoverable--focus--BoxShadow: var(--pf-c-table--tbody--m-hoverable--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-hoverable--focus--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tbody--m-hoverable--active--BoxShadow: var(--pf-c-table--tbody--m-hoverable--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-hoverable--active--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tbody--m-hoverable--m-expanded--BorderColor: var(--pf-global--active-color--400, #73bcf7);
	--pf-c-table--tbody--m-hoverable--m-selected--hover--tr--BoxShadow: var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16)) inset, var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-selected--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-table--tbody--m-selected--BoxShadow--top: var(--pf-c-table--tr--BoxShadow--top--base);
	--pf-c-table--tbody--m-selected--BoxShadow: var(--pf-c-table--tbody--m-selected--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-selected--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs, 0.25rem));
	--pf-c-table--tbody--m-selected--after--BorderLeftWidth: var(--pf-c-table__expandable-row--after--border-width--base);
	--pf-c-table--tbody--m-selected--after--BorderLeftColor: var(--pf-global--active-color--100, #06c);
	--pf-c-table--tbody--m-selected--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-selected--hover--tbody--m-selected--BoxShadow: var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16)) inset, var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--tbody--m-selected--tbody--m-selected--hover--BoxShadow: var(--pf-c-table--tr--m-selected--BoxShadow--top), var(--pf-global--BoxShadow--sm-bottom, 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-table--thead--m-nested-column-header--button--OutlineOffset: -0.1875rem;
	--pf-c-table--thead--m-nested-column-header--tr--PaddingTop: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-table--thead--m-nested-column-header--tr--PaddingBottom: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-table__subhead--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-table--m-striped__tr--BackgroundColor: var(--pf-global--BackgroundColor--light-200);
	color: var(--pf-global--Color--100);
  display: inline-grid;
	grid-auto-rows: min-content;
}

@media (max-width: 768px) {
  :host {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop, 0);
    --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight, 0);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom, 0);
    --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft, 0);
    --pf-c-table__favorite--c-button--MarginTop: auto;
    --pf-c-table__favorite--c-button--MarginRight: auto;
    --pf-c-table__favorite--c-button--MarginBottom: auto;
    --pf-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none;
  }
}
`;
export default styles;

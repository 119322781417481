import {css} from 'lit';
export const styles = css`:host(:empty),
:host([compound-expand]) {
  padding: 0;
}

:host([compound-expand]:hover) {
  --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-c-table__compound-expansion-toggle__button--after--BorderTopWidth: var(--pf-c-table__compound-expansion-toggle__button--after--border-width--base);
}

:host([compound-expand]:focus-within) {
  outline-offset: var(--pf-c-table__button--OutlineOffset);
}

:host([compound-expand][expanded]) {
  --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-c-table__compound-expansion-toggle__button--after--BorderTopWidth: var(--pf-c-table__compound-expansion-toggle__button--after--border-width--base);
  --pf-c-table__compound-expansion-toggle__button--before--Left: 0;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  :host([compound-expand]:focus-within) {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

@media (min-width: 768px) {
  :host {
    padding: 1.5rem 1rem;
  }
}

button {
  position: relative;
  width: 100%;
  padding: var(--pf-c-table--cell--PaddingTop) var(--pf-c-table--cell--PaddingRight) var(--pf-c-table--cell--PaddingBottom) var(--pf-c-table--cell--PaddingLeft);
  font-size: inherit;
  font-weight: inherit;
  color: var(--pf-c-table__button--Color);
  text-align: left;
  white-space: inherit;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background-color: var(--pf-c-table__button--BackgroundColor);
  border: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

button::before,
button::after {
  position: absolute;
  inset-inline-end: 0;
  content: "";
  border-style: solid;
  border-width: 0;
  border-block-start-width: 0px;
}

button::before {
  inset-block-start: 0;
  inset-block-end: var(--pf-c-table__compound-expansion-toggle__button--before--Bottom);
  inset-inline-start: var(--pf-c-table__compound-expansion-toggle__button--before--Left);
  border-color: var(--pf-c-table__compound-expansion-toggle__button--before--BorderColor);
  border-inline-start-width: var(--pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth);
}

:host([expanded]) {
  border-bottom: var(--pf-c-table--BackgroundColor) solid var(--pf-c-table__compound-expansion-toggle__button--before--border-width--base);
  z-index: 1;
}

button::after {
  inset-block-start: var(--pf-c-table__compound-expansion-toggle__button--after--Top);
  inset-inline-start: var(--pf-c-table__compound-expansion-toggle__button--after--Left);
  pointer-events: none;
  border-color: var(--pf-c-table__compound-expansion-toggle__button--after--BorderColor);
  border-block-start-width: var(--pf-c-table__compound-expansion-toggle__button--after--BorderTopWidth);
}

button:active,
button:focus,
button:hover {
  outline: 0;
}

button:active {
  color: var(--pf-c-table__button--active--Color);
}

button:focus {
  color: var(--pf-c-table__button--focus--Color);
}

button:hover {
  color: var(--pf-c-table__button--hover--Color);
}

`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  display: inline-block;
	--pf-c-avatar--BorderColor: transparent;
	--pf-c-avatar--BorderWidth: 0;
	--pf-c-avatar--BorderRadius: var(--pf-global--BorderRadius--lg, 30em);
	--pf-c-avatar--Width: 2.25rem;
	--pf-c-avatar--Height: 2.25rem;
	--pf-c-avatar--m-sm--Width: 1.5rem;
	--pf-c-avatar--m-sm--Height: 1.5rem;
	--pf-c-avatar--m-md--Width: 2.25rem;
	--pf-c-avatar--m-md--Height: 2.25rem;
	--pf-c-avatar--m-lg--Width: 4.5rem;
	--pf-c-avatar--m-lg--Height: 4.5rem;
	--pf-c-avatar--m-xl--Width: 8rem;
	--pf-c-avatar--m-xl--Height: 8rem;
	--pf-c-avatar--m-light--BorderColor: var(--pf-global--BorderColor--dark-100, #d2d2d2);
	--pf-c-avatar--m-light--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-avatar--m-dark--BorderColor: var(--pf-global--palette--black-700, #4f5255);
	--pf-c-avatar--m-dark--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
	width: var(--pf-c-avatar--Width);
	height: var(--pf-c-avatar--Height);
	border-radius: var(--pf-c-avatar--BorderRadius);
}

:host([hidden]),
[hidden] {
  display: none !important;
}

svg,
img {
  display: inline;
  object-fit: cover;
  width: var(--pf-c-avatar--Width);
  height: var(--pf-c-avatar--Height);
  border-radius: var(--pf-c-avatar--BorderRadius);
  border: var(--pf-c-avatar--BorderWidth) solid var(--pf-c-avatar--BorderColor);
}

:host([border]) {
  --pf-c-avatar--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
}

:host([border="dark"]) {
  --pf-c-avatar--BorderColor: var(--pf-c-avatar--m-dark--BorderColor);
}

:host([size="sm"]) {
  --pf-c-avatar--Width: var(--pf-c-avatar--m-sm--Width);
  --pf-c-avatar--Height: var(--pf-c-avatar--m-sm--Height);
}

:host([size="md"]) {
  --pf-c-avatar--Width: var(--pf-c-avatar--m-md--Width);
  --pf-c-avatar--Height: var(--pf-c-avatar--m-md--Height);
}

:host([size="lg"]) {
  --pf-c-avatar--Width: var(--pf-c-avatar--m-lg--Width);
  --pf-c-avatar--Height: var(--pf-c-avatar--m-lg--Height);
}

:host([size="xl"]) {
  --pf-c-avatar--Width: var(--pf-c-avatar--m-xl--Width);
  --pf-c-avatar--Height: var(--pf-c-avatar--m-xl--Height);
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
	font-size: var(--pf-c-button--FontSize);
	font-weight: var(--pf-c-button--FontWeight);
	line-height: var(--pf-c-button--LineHeight);
	text-align: center;
	white-space: nowrap;
  font-size: var(--pf-c-button--FontSize,
    var(--pf-global--FontSize--md, 1rem));
  font-weight: var(--pf-c-button--FontWeight,
    var(--pf-global--FontWeight--normal, 400));
  line-height: var(--pf-c-button--LineHeight,
    var(--pf-global--LineHeight--md, 1.5));
  display: inline-block;
  height: max-content;
  cursor: pointer;
  position: relative;
  font-family: inherit;
  border-width: 0;
  border-style: solid;
  border-radius: var(--pf-c-button--BorderRadius,
    var(--pf-global--BorderRadius--sm, 3px));
}

:host([hidden]),
[hidden] {
  display: none !important;
}

:host([inline]) {
  display: inline;
  --pf-c-button--PaddingTop: 0;
  --pf-c-button--PaddingLeft: 0;
  --pf-c-button--PaddingBottom: 0;
  --pf-c-button--PaddingRight: 0;
}

pf-icon,
::slotted(pf-icon) {
  color: currentcolor;
  padding-inline-start: var(--_button-icon-padding-inline-start);
  padding-inline-end: var(--_button-icon-padding-inline-end);
  vertical-align: var(--_button-icon-vertical-align);
}

#icon {
  margin-inline-end: var(--pf-c-button__icon--m-start--MarginRight,
      var(--pf-global--spacer--xs, 0.25rem));
}

#button {
  display: inline-block;
  color: var(--_button-color);
  padding:
    var(--pf-c-button--PaddingTop,
      var(--pf-global--spacer--form-element, 0.375rem))
    var(--pf-c-button--PaddingRight,
      var(--pf-global--spacer--md, 1rem))
    var(--pf-c-button--PaddingBottom,
      var(--pf-global--spacer--form-element, 0.375rem))
    var(--pf-c-button--PaddingLeft,
      var(--pf-global--spacer--md, 1rem));
}

#button::before,#button::after {
    position: absolute;
    inset: 0;
    content: "";
  }

#button::before {
    background-color: var(--_button-background-color);
    border-radius: var(--pf-c-button--BorderRadius,
      var(--pf-global--BorderRadius--sm, 3px));
  }

#button::after {
    pointer-events: none;
    border-style: solid;
    border-width: var(--pf-c-button--after--BorderWidth);
    border-color: var(--pf-c-button--after--BorderColor);
    border-radius: var(--pf-c-button--after--BorderRadius);
  }

#button.anchor {
    text-decoration: none;
  }

#button.anchor::after {
      pointer-events: all;
    }

#text {
  display: inline;
  position: relative;
}

:host(:focus) {
  --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--focus--Color,
    var(--pf-global--Color--light-100, #fff));
  --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--focus--BackgroundColor,
    var(--pf-global--primary-color--200, #004080));
  --pf-c-button--after--BorderWidth: var(--pf-c-button--focus--after--BorderWidth,
    var(--pf-global--BorderWidth--md, 2px));
  /* DANGER */
  --pf-c-button--m-danger--Color: var(--pf-c-button--m-danger--focus--Color,
    var(--pf-global--Color--light-100, #fff));
  --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-danger--focus--BackgroundColor,
    var(--pf-global--danger-color--200, #a30000));
  /* LINK */
  --pf-c-button--m-link--Color: var(--pf-c-button--m-link--focus--Color,
    var(--pf-global--link--Color--hover, #004080));
  --pf-c-button--m-link--BackgroundColor: var(--pf-c-button--m-link--hover--BackgroundColor, transparent);
  /* PLAIN */
  --pf-c-button--m-plain--Color: var(--pf-c-button--m-plain--focus--Color,
    var(--pf-global--Color--100, #151515));
  --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--m-plain--focus--BackgroundColor,
    transparent);
}

:host(:hover) {
  --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--hover--Color,
    var(--pf-global--Color--light-100, #fff));
  --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--hover--BackgroundColor,
    var(--pf-global--primary-color--200, #004080));
  --pf-c-button--after--BorderWidth: var(--pf-c-button--hover--after--BorderWidth,
    var(--pf-global--BorderWidth--md, 2px));
  /* DANGER */
  --pf-c-button--m-danger--Color: var(--pf-c-button--m-danger--hover--Color,
    var(--pf-global--Color--light-100, #fff));
  --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-danger--hover--BackgroundColor,
    var(--pf-global--danger-color--200, #a30000));
  /* LINK */
  --pf-c-button--m-link--Color: var(--pf-c-button--m-link--hover--Color,
    var(--pf-global--link--Color--hover, #004080));
  --pf-c-button--m-link--BackgroundColor: var(--pf-c-button--m-link--hover--BackgroundColor, transparent);
  /* PLAIN */
  --pf-c-button--m-plain--Color: var(--pf-c-button--m-plain--hover--Color,
    var(--pf-global--Color--100, #151515));
  --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--m-plain--hover--BackgroundColor,
    transparent);
}

:host(:active) {
  --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--active--Color,
    var(--pf-global--Color--light-100, #fff));
  --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--active--BackgroundColor,
    var(--pf-global--primary-color--200, #004080));
  --pf-c-button--after--BorderWidth: var(--pf-c-button--active--after--BorderWidth,
    var(--pf-global--BorderWidth--md, 2px));
  /* DANGER */
  --pf-c-button--m-danger--Color: var(--pf-c-button--m-danger--active--Color,
    var(--pf-global--Color--light-100, #fff));
  --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-danger--active--BackgroundColor,
    var(--pf-global--danger-color--200, #a30000));
  /* LINK */
  --pf-c-button--m-link--Color: var(--pf-c-button--m-link--active--Color,
    var(--pf-global--link--Color--hover, #004080));
  --pf-c-button--m-link--BackgroundColor: var(--pf-c-button--m-link--active--BackgroundColor, transparent);
}

.disabled,
:host(:disabled),
:host([danger]:disabled),
:host([link]:disabled) .link {
  pointer-events: none;
  cursor: default;
}

[part=icon] {
  --pf-icon--size: 16px;
  display: inline-flex;
  align-items: center;
  position: absolute;
}

[part=icon] ::slotted(*) {
    width: 16px;
    max-width: 16px;
    height: 16px;
    max-height: 16px;
  }

.hasIcon [part=icon] {
  cursor: pointer;
}

.hasIcon #button {
  position: absolute;
  inset: 0;
}

/******************************
 *                            *
 *           PLAIN            *
 *                            *
 ******************************/

#button.plain {
  --pf-c-button--disabled--BackgroundColor: var(--pf-c-button--m-plain--disabled--BackgroundColor, transparent);
  --pf-c-button--after--BorderWidth: 0 !important;
  --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--after--BorderColor,
    var(--pf-global--Color--100, #151515));
  --pf-c-button--disabled--Color: var(--pf-c-button--m-plain--disabled--Color,
    var(--pf-global--disabled-color--200, #d2d2d2));
  --_button-color: var(--pf-c-button--m-plain--Color,
    var(--pf-global--Color--200, #6a6e73));
  --_button-background-color: var(--pf-c-button--m-plain--BackgroundColor,
    transparent);
}

:host(:active) #button.plain {
    --pf-c-button--m-plain--Color: var(--pf-c-button--m-plain--active--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--m-plain--active--BackgroundColor,
      tranparent);
  }

#button.plain:not(.hasIcon) [part=icon],#button.plain.loading [part=icon] {
    left: 16px;
  }

#button.plain [part=icon] {
    display: contents;
  }

#button.plain.disabled,#button.plain.link.disabled {
    --_button-color: var(--pf-c-button--disabled--Color,
      var(--pf-c-button--m-plain--disabled--Color,
        var(--pf-global--disabled--color--200, #d2d2d2)));
  }

.hasIcon:not(.plain) [part=icon] {
  position: relative;
}

/******************************
 *                            *
 *    ICON POSITION RIGHT     *
 *                            *
 ******************************/

:host([icon-position=right]) .loading [part=icon] {
  order: 1;
}

:host([icon-position=right]) #button.hasIcon  {
  padding-left: var(--pf-c-button--PaddingLeft, var(--pf-global--spacer--md, 1rem));
  padding-right: calc(16px + 8px + var(--pf-c-button--PaddingRight, var(--pf-global--spacer--md, 1rem)));
}

/******************************
 *                            *
 *          WARNING           *
 *                            *
 ******************************/

#button.warning {
  --_button-color: var(--pf-c-button--m-warning--Color,
    var(--pf-global--Color--dark-100, #151515));
  --_button-background-color: var(--pf-c-button--m-warning--BackgroundColor,
    var(--pf-global--warning-color--100, #f0ab00));
}

:host(:focus) #button.warning {
    --pf-c-button--m-warning--Color: var(--pf-c-button--m-warning--focus--Color,
      var(--pf-global--Color--dark-100, #151515));
    --pf-c-button--m-warning--BackgroundColor: var(--pf-c-button--m-warning--focus--BackgroundColor,
      var(--pf-global--palette--gold-500, #c58c00));
  }

:host(:hover) #button.warning {
    --pf-c-button--m-warning--Color: var(--pf-c-button--m-warning--hover--Color,
      var(--pf-global--Color--dark-100, #151515));
    --pf-c-button--m-warning--BackgroundColor: var(--pf-c-button--m-warning--hover--BackgroundColor,
      var(--pf-global--palette--gold-500, #c58c00));
  }

:host(:active) #button.warning {
    --pf-c-button--m-warning--Color: var(--pf-c-button--m-warning--active--Color,
      var(--pf-global--Color--dark-100, #151515));
    --pf-c-button--m-warning--BackgroundColor: var(--pf-c-button--m-warning--active--BackgroundColor,
      var(--pf-global--palette--gold-500, #c58c00));
  }

#button.warning.disabled {
    --_button-color: var(--pf-c-button--disabled--Color,
      var(--pf-global--disabled-color--100, #6a6e73));
    --_button-background-color: var(--pf-c-button--disabled--BackgroundColor,
      var(--pf-global--disabled-color--200, #d2d2d2));
  }

/******************************
 *                            *
 *          LOADING           *
 *                            *
 ******************************/

:host([loading]) #button {
  position: relative;
  display: flex;
  align-items: center;
}

#button.loading [part=icon] {
    display: inline-block;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    top: var(--pf-c-button__progress--Top, 50%);
    left: var(--pf-c-button__progress--Left,
      var(--pf-global--spacer--md, 1rem));
    line-height: 1;
    transform: translate(
      var(--pf-c-button__progress--TranslateX, 0),
      var(--pf-c-button__progress--TranslateY, -50%));
    margin-inline-end: var(--pf-c-button__icon--m-start--MarginRight,
      var(--pf-global--spacer--xs, 0.25rem));
  }

#button.loading.primary:not(.plain),#button.loading.danger {
    --pf-c-spinner--Color: white;
  }

#button.loading:not(.plain) {
    padding-left: calc(12px + var(--pf-c-button--PaddingLeft, var(--pf-global--spacer--md, 1rem)));
    --pf-c-button--PaddingRight: var(--pf-c-button--m-in-progress--PaddingRight,
      var(--pf-global--spacer--md, 1rem));
    --pf-c-button--PaddingLeft: var(--pf-c-button--m-in-progress--PaddingLeft,
      calc(
        var(--pf-global--spacer--md, 1rem) + var(--pf-c-button__progress--width,
          calc(var(--pf-global--icon--FontSize--md, 1.125rem) + var(--pf-global--spacer--sm, 0.5rem))) / 2));
  }

/******************************
 *                            *
 *         SECONDARY          *
 *                            *
 ******************************/

#button.secondary {
  --pf-c-button--m-danger--Color: var(--pf-c-button--m-secondary--m-danger--Color,
    var(--pf-global--danger-color--100, #c9190b));
  --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-secondary--m-danger--BackgroundColor, transparent);
  --_button-color: var(--pf-c-button--m-secondary--Color,
    var(--pf-global--primary-color--100, #06c));
  --_button-background-color: var(--pf-c-button--m-secondary--BackgroundColor, transparent);
  --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--after--BorderColor,
    var(--pf-global--primary-color--100, #06c));
}

:host(:focus) #button.secondary {
    --pf-c-button--m-secondary--Color: var(--pf-c-button--m-secondary--focus--Color,
      var(--pf-global--primary-color--100, #06c));
    --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--m-secondary--focus--BackgroundColor, transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--focus--after--BorderColor,
      var(--pf-global--primary-color--100, #06c));
    /* DANGER */
    --pf-c-button--m-danger--Color: var(--pf-c-button--m-secondary--m-danger--focus--Color,
      var(--pf-global--danger--color--200, #a30000));
    --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-secondary--m-danger--focus--BackgroundColor, transparent);
  }

:host(:hover) #button.secondary {
    --pf-c-button--m-secondary--Color: var(--pf-c-button--m-secondary--hover--Color,
      var(--pf-global--primary-color--100, #06c));
    --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--m-secondary--hover--BackgroundColor,
      transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--hover--after--BorderColor,
      var(--pf-global--primary-color--100, #06c));
    /* DANGER */
    --pf-c-button--m-danger--Color: var(--pf-c-button--m-secondary--m-danger--hover--Color,
      var(--pf-global--danger--color--200, #a30000));
    --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-secondary--m-danger--hover--BackgroundColor, transparent);
  }

:host(:active) #button.secondary {
    --pf-c-button--m-secondary--Color: var(--pf-c-button--m-secondary--active--Color,
      var(--pf-global--primary-color--100, #06c));
    --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--m-secondary--active--BackgroundColor, transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--active--after--BorderColor,
      var(--pf-global--primary-color--100, #06c));
    /* DANGER */
    --pf-c-button--m-danger--Color: var(--pf-c-button--m-secondary--m-danger--active--Color,
      var(--pf-global--danger--color--200, #a30000));
    --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-secondary--m-danger--active--BackgroundColor, transparent);
  }

#button.secondary.danger {
    --_button-color: var(--pf-c-button--m-secondary--m-danger--Color,
      var(--pf-global--danger--color--100, #c9190b));
    --_button-background-color: var(--pf-c-button--m-secondary--m-danger--BackgroundColor, transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--m-danger--after--BorderColor,
      var(--pf-global--danger--color--100, #c9190b));
  }

:host(:focus) #button.secondary.danger {
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--m-danger--focus--after--BorderColor,
        var(--pf-global--danger--color--100, #c9190b));
    }

:host(:hover) #button.secondary.danger {
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--m-danger--hover--after--BorderColor,
        var(--pf-global--danger--color--100, #c9190b));
    }

:host(:active) #button.secondary.danger {
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--m-danger--active--after--BorderColor,
        var(--pf-global--danger--color--100, #c9190b));
    }

/******************************
 *                            *
 *         TERTIARY           *
 *                            *
 ******************************/

#button.tertiary {
  --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--after--BorderColor,
    var(--pf-global--Color--100, #151515));
  --_button-color: var(--pf-c-button--m-tertiary--Color,
    var(--pf-global--Color--100, #151515));
  --_button-background-color: var(--pf-c-button--m-tertiary--BackgroundColor, transparent);
}

:host(:focus) #button.tertiary {
    --pf-c-button--m-tertiary--Color: var(--pf-c-button--m-tertiary--focus--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-tertiary--BackgroundColor: var(--pf-c-button--m-tertiary--focus--BackgroundColor,
      transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--focus--after--BorderColor,
      var(--pf-global--Color--100, #151515));
  }

:host(:hover) #button.tertiary {
    --pf-c-button--m-tertiary--Color: var(--pf-c-button--m-tertiary--hover--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-tertiary--BackgroundColor: var(--pf-c-button--m-tertiary--hover--BackgroundColor, transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--hover--after--BorderColor,
      var(--pf-global--Color--100, #151515));
  }

:host(:active) #button.tertiary {
    --pf-c-button--m-tertiary--Color: var(--pf-c-button--m-tertiary--active--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-tertiary--BackgroundColor: var(--pf-c-button--m-tertiary--active--BackgroundColor, transparent);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--active--after--BorderColor,
      var(--pf-global--Color--100, #151515));
  }

/******************************
 *                            *
 *          CONTROL           *
 *                            *
 ******************************/

#button.control {
  --pf-c-button--BorderRadius: var(--pf-c-button--m-control--BorderRadius, 0);
  --pf-c-button--disabled--BackgroundColor: var(--pf-c-button--m-control--disabled--BackgroundColor,
    var(--pf-global--disabled-color--300, #f0f0f0));
  --pf-c-button--after--BorderRadius: 0;
  --pf-c-button--after--BorderWidth: var(--pf-c-button--m-control--after--BorderWidth,
    var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-button--after--BorderColor:
    var(--pf-c-button--m-control--after--BorderTopColor,
      var(--pf-global--BorderColor--300, #f0f0f0))
    var(--pf-c-button--m-control--after--BorderRightColor,
      var(--pf-global--BorderColor--300, #f0f0f0))
    var(--pf-c-button--m-control--after--BorderBottomColor,
      var(--pf-global--BorderColor--200, #8a8d90))
    var(--pf-c-button--m-control--after--BorderLeftColor,
      var(--pf-global--BorderColor--300, #f0f0f0));
  --_button-color: var(--pf-c-button--m-control--Color,
    var(--pf-global--Color--100, #151515));
  --_button-background-color: var(--pf-c-button--m-control--BackgroundColor,
    var(--pf-global--BackgroundColor--100, #fff));
}

:host(:focus) #button.control {
    --pf-c-button--m-control--Color: var(--pf-c-button--m-control--focus--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--focus--BackgroundColor,
      var(--pf-global--BackgroundColor--100, #fff));
    --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--focus--after--BorderBottomColor,
      var(--pf-global--active-color--100, #06c));
  }

:is(:host(:focus) #button.control)::after {
      border-block-end-width: var(--pf-c-button--m-control--focus--after--BorderBottomWidth,
        var(--pf-global--BorderWidth--md, 2px));
    }

:host(:hover) #button.control {
    --pf-c-button--m-control--Color: var(--pf-c-button--m-control--hover--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--hover--BackgroundColor,
      var(--pf-global--BackgroundColor--100, #fff));
    --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--hover--after--BorderBottomColor,
      var(--pf-global--active-color--100, #06c));
  }

:is(:host(:hover) #button.control)::after {
      border-block-end-width: var(--pf-c-button--m-control--hover--after--BorderBottomWidth,
        var(--pf-global--BorderWidth--md, 2px));
    }

:host(:active) #button.control {
    --pf-c-button--m-control--Color: var(--pf-c-button--m-control--active--Color,
      var(--pf-global--Color--100, #151515));
    --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--active--BackgroundColor,
      var(--pf-global--BackgroundColor--100, #fff));
    --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--active--after--BorderBottomColor,
      var(--pf-global--active-color--100, #06c));
  }

:is(:host(:active) #button.control)::after {
      border-block-end-width: var(--pf-c-button--m-control--active--after--BorderBottomWidth,
        var(--pf-global--BorderWidth--md, 2px));
    }

/******************************
 *                            *
 *           LINK             *
 *                            *
 ******************************/

#button.link {
  --pf-c-button--disabled--BackgroundColor: var(--pf-c-button--m-link--disabled--BackgroundColor, transparent);
  --_button-color: var(--pf-c-button--m-link--Color, var(--pf-global--link--Color, #06c));
  --_button-background-color: var(--pf-c-button--m-link--BackgroundColor,
    var(--pf-c-button--m-link--BackgroundColor, transparent));
}

:host(:hover) #button.link.inline {
      text-decoration: var(--pf-c-button--m-link--m-inline--hover--TextDecoration,
        var(--pf-global--link--TextDecoration--hover, underline));
    }

#button.link.danger {
    --pf-c-button--m-danger--Color: var(--pf-c-button--m-link--m-danger--Color,
        var(--pf-global--danger-color--100, #c9190b));
    --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-link--m-danger--BackgroundColor, transparent);
  }

:host(:hover) #button.link.danger {
      --pf-c-button--m-link--m-danger--Color: var(--pf-c-button--m-link--m-danger--hover--Color,
          var(--pf-global--danger-color--200, #a30000));
      --pf-c-button--m-link--m-danger--BackgroundColor: var(--pf-c-button--m-link--m-danger--hover--BackgroundColor, transparent);
    }

:host(:focus) #button.link.danger {
      --pf-c-button--m-link--m-danger--Color: var(--pf-c-button--m-link--m-danger--focus--Color,
        var(--pf-global--danger-color--200, #a30000));
      --pf-c-button--m-link--m-danger--BackgroundColor: var(--pf-c-button--m-link--m-danger--focus--BackgroundColor, transparent);
    }

:host(:active) #button.link.danger {
      --pf-c-button--m-link--m-danger--Color: var(--pf-c-button--m-link--m-danger--active--Color,
        var(--pf-global--danger-color--200, #a30000));
      --pf-c-button--m-link--m-danger--BackgroundColor: var(--pf-c-button--m-link--m-danger--active--BackgroundColor, transparent);
    }

/******************************
 *                            *
 *         DISABLED           *
 *                            *
 ******************************/

:host(:is(:disabled,[disabled])) {
  pointer-events: none;
  cursor: default;
}

#button.disabled:not(.plain) {
    --_button-color: var(--pf-c-button--disabled--Color,
      var(--pf-global--disabled-color--100, #6a6e73));
    --_button-background-color: var(--pf-c-button--disabled--BackgroundColor,
      var(--pf-global--disabled-color--200, #d2d2d2));
  }

#button.disabled::after {
    border-color: var(--pf-c-button--disabled--after--BorderColor, transparent);
  }

/******************************
 *                            *
 *           BLOCK            *
 *                            *
 ******************************/

:host([block]) {
  display: flex;
  width: 100%;
  justify-content: center;
}

/******************************
 *                            *
 *           LARGE            *
 *                            *
 ******************************/

:host([size="large"]) {
  --pf-c-button--PaddingTop: var(--pf-c-button--m-display-lg--PaddingTop,
    var(--pf-global--spacer--md, 1rem));
  --pf-c-button--PaddingRight: var(--pf-c-button--m-display-lg--PaddingRight,
    var(--pf-global--spacer--xl, 2rem));
  --pf-c-button--PaddingBottom: var(--pf-c-button--m-display-lg--PaddingBottom,
    var(--pf-global--spacer--md, 1rem));
  --pf-c-button--PaddingLeft: var(--pf-c-button--m-display-lg--PaddingLeft,
    var(--pf-global--spacer--xl, 2rem));
  --pf-c-button--FontWeight: var(--pf-c-button--m-display-lg--FontWeight,
    var(--pf-global--FontWeight--bold, 700));
}

/******************************
 *                            *
 *           SMALL            *
 *                            *
 ******************************/

:host([size="small"]) {
  --pf-c-button--FontSize: var(--pf-c-button--m-small--FontSize,
    var(--pf-global--FontSize--md, 1rem));
}

/******************************
 *                            *
 *          DANGER            *
 *                            *
 ******************************/

#button.danger {
  --_button-color: var(--pf-c-button--m-danger--Color,
    var(--pf-global--Color--light-100, #fff));
  --_button-background-color: var(--pf-c-button--m-danger--BackgroundColor,
    var(--pf-global--danger-color--100, #c9190b));
}

`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  display: block;
}

[hidden] {
  display: none !important;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #777;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #6a6e73;
}

::slotted(hr) {
  margin: 0;
  border-color: var(--pf-c-divider--BackgroundColor, var(--pf-global--BorderColor--100, #d2d2d2));
  border-style: solid;
}

::slotted([role="separator"]:not(hr)) {
  width: 100%;
  height: 1px;
  background-color: var(--pf-c-divider--BackgroundColor, var(--pf-global--BorderColor--100, #d2d2d2));
  padding: 0px;
  margin: 0px;
  border: 0;
  display: block;
  pointer-events: none;
}
`;
export default styles;

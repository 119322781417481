import {css} from 'lit';
export const styles = css`:host {
  display: block;
}

#container {
  display: contents;
  --pf-c-jump-links__list--PaddingTop: 0;
  --pf-c-jump-links__list--PaddingBottom: 0;
  --pf-c-jump-links__link--PaddingTop: var(--pf-c-jump-links__list__list__link--PaddingTop,
    var(--pf-global--spacer--sm, 0.5rem));
  --pf-c-jump-links__link--PaddingBottom: var(--pf-c-jump-links__list__list__link--PaddingBottom,
    var(--pf-global--spacer--sm, 0.5rem));
  --pf-c-jump-links__link--PaddingLeft: var(--pf-c-jump-links__list__list__link--PaddingLeft,
    var(--pf-global--spacer--lg, 1.5rem));
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  display: block;
}

:host([hidden]) {
  display: none;
}

:host([box="light"]) {
  background-color: var(--pf-c-tab-content--m-light-300, var(--pf-global--BackgroundColor--light-300, #f0f0f0));
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  position: relative;
  display:  inline-block;
  line-height:  0;
  height: fit-content !important;
  width: fit-content !important;
}

#container {
  display: grid;
  grid-template: 1fr / 1fr;
  place-content: center;
}

#container.content ::slotted(*) {
  display: none;
}

svg {
  fill: currentcolor;
}

:host([size=sm]) #container { --_size: var(--pf-global--icon--FontSize--sm, 10px); }
:host([size=md]) #container { --_size: var(--pf-global--icon--FontSize--md, 18px); }
:host([size=lg]) #container { --_size: var(--pf-global--icon--FontSize--lg, 24px); }
:host([size=xl]) #container { --_size: var(--pf-global--icon--FontSize--xl, 54px); }

#container, svg {
  width: var(--pf-icon--size, var(--_size));
  height: var(--pf-icon--size, var(--_size));
  min-width: var(--pf-icon--size, var(--_size));
  min-height: var(--pf-icon--size, var(--_size));
}

`;
export default styles;

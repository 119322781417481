import {css} from 'lit';
export const styles = css`:host {
  display: block;
}

#container {
  display: contents;
}

slot:not([name]) {
  display: flex;
  flex-direction: column;
  row-gap: var(--pf-global--spacer--md, 1rem);
}

a {
  position: relative;
  display: flex;
  cursor: pointer;
  flex: 1;
  padding-block-start: var(--pf-c-jump-links__link--PaddingTop,
    var(--pf-global--spacer--md, 1rem));
  padding-inline-end: var(--pf-c-jump-links__link--PaddingRight,
    var(--pf-global--spacer--md, 1rem));
  padding-block-end: var(--pf-c-jump-links__link--PaddingBottom,
    var(--pf-global--spacer--md, 1rem));
  padding-inline-start: var(--pf-c-jump-links__link--PaddingLeft,
    var(--pf-global--spacer--md, 1rem));
  text-decoration: none;
  outline-offset: var(--pf-c-jump-links__link--OutlineOffset,
    calc(-1 * var(--pf-global--spacer--sm, 0.5rem)));
  color: var(--pf-c-jump-links__link-text--Color,
    var(--pf-global--Color--200, #6a6e73));
}

a::before {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border-color: var(--pf-c-jump-links__link--before--BorderColor, transparent);
  border-style: solid;
  border-width:
    var(--pf-c-jump-links__link--before--BorderTopWidth,
      var(--pf-c-jump-links__list--before--BorderTopWidth,
        var(--pf-global--BorderWidth--sm, 1px)))
    var(--pf-c-jump-links__link--before--BorderRightWidth, 0)
    var(--pf-c-jump-links__link--before--BorderBottomWidth, 0)
    var(--pf-c-jump-links__link--before--BorderLeftWidth, 0);
}

a:hover {
  --pf-c-jump-links__link-text--Color: var(--pf-c-jump-links__link--hover__link-text--Color,
    var(--pf-global--Color--100, #151515));
}

a:focus {
  --pf-c-jump-links__link-text--Color: var(--pf-c-jump-links__link--focus__link-text--Color,
    var(--pf-global--Color--100, #151515));
}

:host([active]) {
  --pf-c-jump-links__link--before--BorderTopWidth: var(--pf-c-jump-links__item--m-current__link--before--BorderTopWidth,
    var(--pf-global--BorderWidth--lg, 3px));
  --pf-c-jump-links__link--before--BorderLeftWidth: var(--pf-c-jump-links__item--m-current__link--before--BorderLeftWidth, 0);
  --pf-c-jump-links__link--before--BorderColor: var(--pf-c-jump-links__item--m-current__link--before--BorderColor,
    var(--pf-global--primary-color--100, #06c));
  --pf-c-jump-links__link-text--Color: var(--pf-c-jump-links__item--m-current__link-text--Color,
    pfvar(--pf-global--Color--100, #151515));
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  display: block;
  border-bottom: 1px solid var(--pf-global--BorderColor--100, #d2d2d2);
}

:host([disabled]) {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--pf-global--Color--200, #6a6e73) !important;
  background-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
  border-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
  --_active-descendant-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
  --_svg-color: var(--pf-global--Color--200, #6a6e73) !important;
}

slot {
  display: block;
  padding: var(--pf-global--spacer--md, 1rem) 0;
}

slot[name="label"] {
  font-size: var(--pf-global--FontSize--xs, 0.75rem);
  color: var(--pf-global--Color--dark-200, #6a6e73);
  padding: var(--pf-global--spacer--md, 1rem) var(--pf-global--spacer--md, 1rem) 0;
}
`;
export default styles;

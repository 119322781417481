import {css} from 'lit';
export const styles = css`:host {
	--pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-card--BoxShadow: var(--pf-global--BoxShadow--sm, 0 0.0625rem 0.125rem 0 rgba(3, 3, 3, 0.12), 0 0 0.125rem 0 rgba(3, 3, 3, 0.06));
	--pf-c-card--first-child--PaddingTop: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-card--child--PaddingRight: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-card--child--PaddingBottom: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-card--child--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-card--c-divider--child--PaddingTop: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-card__title--FontFamily: var(--pf-global--FontFamily--heading--sans-serif, "RedHatDisplay", "Overpass", overpass, helvetica, arial, sans-serif);
	--pf-c-card__title--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-card__title--FontWeight: var(--pf-global--FontWeight--bold, 700);
	--pf-c-card__title--not--last-child--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-card__body--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-card__footer--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-card__actions--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-card__actions--child--MarginLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-card__header-toggle--MarginTop: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-card__header-toggle--MarginRight: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-card__header-toggle--MarginBottom: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-card__header-toggle--MarginLeft: calc(var(--pf-global--spacer--md, 1rem) * -1);
	--pf-c-card__header-toggle-icon--Transition: var(--pf-global--Transition, all 250ms cubic-bezier(0.42, 0, 0.58, 1));
	--pf-c-card--m-expanded__header-toggle-icon--Rotate: 90deg;
	--pf-c-card--m-hoverable--hover--BoxShadow: var(--pf-global--BoxShadow--lg, 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08));
	--pf-c-card--m-selectable--hover--BoxShadow: var(--pf-global--BoxShadow--lg, 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08));
	--pf-c-card--m-selectable--focus--BoxShadow: var(--pf-global--BoxShadow--lg, 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08));
	--pf-c-card--m-selectable--active--BoxShadow: var(--pf-global--BoxShadow--lg, 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08));
	--pf-c-card--m-selectable--m-selected--BoxShadow: var(--pf-global--BoxShadow--lg, 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08));
	--pf-c-card--m-selectable--m-selected--before--Height: var(--pf-global--BorderWidth--lg, 3px);
	--pf-c-card--m-selectable--m-selected--before--BackgroundColor: var(--pf-global--active-color--100, #06c);
	--pf-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-global--BoxShadow--md, 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06));
	--pf-c-card--m-hoverable-raised--hover--before--BackgroundColor: var(--pf-global--active-color--400, #73bcf7);
	--pf-c-card--m-selectable-raised--before--Right: 0;
	--pf-c-card--m-selectable-raised--before--Bottom: 0;
	--pf-c-card--m-selectable-raised--before--Left: 0;
	--pf-c-card--m-flat--m-selectable-raised--before--Right: calc(-1 * var(--pf-c-card--m-flat--BorderWidth));
	--pf-c-card--m-flat--m-selectable-raised--before--Bottom: calc(-1 * var(--pf-c-card--m-flat--BorderWidth));
	--pf-c-card--m-flat--m-selectable-raised--before--Left: calc(-1 * var(--pf-c-card--m-flat--BorderWidth));
	--pf-c-card--m-selectable-raised--before--Height: var(--pf-global--BorderWidth--xl, 4px);
	--pf-c-card--m-selectable-raised--before--BackgroundColor: transparent;
	--pf-c-card--m-selectable-raised--before--Transition: none;
	--pf-c-card--m-selectable-raised--before--ScaleY: 1;
	--pf-c-card--m-selectable-raised--before--TranslateY: 0;
	--pf-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-global--BoxShadow--md, 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06));
	--pf-c-card--m-selectable-raised--hover--before--BackgroundColor: var(--pf-global--active-color--400, #73bcf7);
	--pf-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-global--BoxShadow--md, 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06));
	--pf-c-card--m-selectable-raised--focus--before--BackgroundColor: var(--pf-global--active-color--400, #73bcf7);
	--pf-c-card--m-selectable-raised--active--BoxShadow: var(--pf-global--BoxShadow--md, 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06));
	--pf-c-card--m-selectable-raised--active--before--BackgroundColor: var(--pf-global--active-color--400, #73bcf7);
	--pf-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor: var(--pf-global--active-color--100, #06c);
	--pf-c-card--m-selectable-raised--m-selected-raised--BoxShadow: var(--pf-global--BoxShadow--lg, 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08));
	--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base: -0.5rem;
	--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base);
	--pf-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-c-card--m-flat--BorderWidth));
	--pf-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-c-card--m-rounded--BorderRadius));
	--pf-c-card--m-selectable-raised--m-selected-raised--ZIndex: var(--pf-global--ZIndex--xs, 100);
	--pf-c-card--m-selectable-raised--m-selected-raised--Transition: transform .25s linear, box-shadow .25s linear;
	--pf-c-card--m-selectable-raised--m-selected-raised--before--Transition: transform .25s linear;
	--pf-c-card--m-selectable-raised--m-selected-raised--before--TranslateY: calc(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY) * -1);
	--pf-c-card--m-selectable-raised--m-selected-raised--before--ScaleY: 2;
	--pf-c-card--m-non-selectable-raised--BackgroundColor: var(--pf-global--BackgroundColor--light-200, #fafafa);
	--pf-c-card--m-non-selectable-raised--before--BackgroundColor: var(--pf-global--disabled-color--200, #d2d2d2);
	--pf-c-card--m-non-selectable-raised--before--ScaleY: 2;
	--pf-c-card--m-flat--m-non-selectable-raised--before--BorderColor: var(--pf-global--disabled-color--200, #d2d2d2);
	--pf-c-card--m-compact__body--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-card--m-compact__footer--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-card--m-compact--first-child--PaddingTop: var(--pf-global--spacer--md, 1rem);
	--pf-c-card--m-compact--child--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-card--m-compact--child--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-card--m-compact--child--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-card--m-compact--c-divider--child--PaddingTop: var(--pf-global--spacer--md, 1rem);
	--pf-c-card--m-compact__title--not--last-child--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-card--m-display-lg__title--FontSize: var(--pf-global--FontSize--xl, 1.25rem);
	--pf-c-card--m-display-lg--first-child--PaddingTop: var(--pf-global--spacer--xl, 2rem);
	--pf-c-card--m-display-lg--child--PaddingRight: var(--pf-global--spacer--xl, 2rem);
	--pf-c-card--m-display-lg--child--PaddingBottom: var(--pf-global--spacer--xl, 2rem);
	--pf-c-card--m-display-lg--child--PaddingLeft: var(--pf-global--spacer--xl, 2rem);
	--pf-c-card--m-display-lg--c-divider--child--PaddingTop: var(--pf-global--spacer--xl, 2rem);
	--pf-c-card--m-display-lg__title--not--last-child--PaddingBottom: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-card--m-flat--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-card--m-flat--BorderColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-card--m-rounded--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
	--pf-c-card--m-full-height--Height: 100%;
	--pf-c-card--m-plain--BoxShadow: none;
	--pf-c-card--m-plain--BackgroundColor: transparent;
	--pf-c-card__header--m-toggle-right--toggle--MarginRight: calc(var(--pf-global--spacer--form-element, 0.375rem) * -1);
	--pf-c-card__header--m-toggle-right--toggle--MarginLeft: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-card__header--m-toggle-right--actions--MarginRight: 0;
	--pf-c-card__input--focus--BorderWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-card__input--focus--BorderColor: var(--pf-global--primary-color--100, #06c);
  display: flex;
  flex-direction: column;
	background-color: var(--pf-c-card--BackgroundColor);
	box-shadow: var(--pf-c-card--BoxShadow);
}

[hidden],
.empty {
  display: none !important;
}

header {
  padding-block-start: var(--pf-c-card--first-child--PaddingTop);
  padding-block-end: var(--pf-c-card__title--not--last-child--PaddingBottom);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

header ::slotted(*) {
  margin-block: 0 !important;

  font-family: var(--pf-c-card__title--FontFamily) !important;
  font-size: var(--pf-c-card__title--FontSize) !important;
  font-weight: var(--pf-c-card__title--FontWeight) !important;
}

header ::slotted(pf-dropdown) {
  margin-inline-start: auto;
}

article {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

[part="header"],
[part="body"],
[part="footer"] {
  padding-inline-start: var(--pf-c-card--child--PaddingLeft);
  padding-inline-end: var(--pf-c-card--child--PaddingRight);
  padding-block-end: var(--pf-c-card--child--PaddingBottom);
}

#title {
  display: block;
  flex: 1 0 100%;
  padding-block-start: var(--pf-c-card__title--not--last-child--PaddingBottom);
}

[part="body"] {
  font-size: var(--pf-c-card__body--FontSize);
  flex: var(--pf-c-card__body--FullHeight--Flex);
}

[part="body"] ::slotted(:not([slot]):first-of-type) {
  margin-block-start: 0 !important;
}

[part="body"] ::slotted(:not([slot]):last-of-type) {
  margin-block-end: 0 !important;
}

[part="footer"] {
  margin-block-start: auto;
  display: flex;
  gap: 0.5em;
  inset-block-end: 0;
  font-size: var(--pf-c-card__footer--FontSize);
}

:host([size="compact"]) {
	--pf-c-card__body--FontSize: var(--pf-c-card--m-compact__body--FontSize);
	--pf-c-card__footer--FontSize: var(--pf-c-card--m-compact__footer--FontSize);
	--pf-c-card--first-child--PaddingTop: var(--pf-c-card--m-compact--first-child--PaddingTop);
	--pf-c-card--child--PaddingRight: var(--pf-c-card--m-compact--child--PaddingRight);
	--pf-c-card--child--PaddingBottom: var(--pf-c-card--m-compact--child--PaddingBottom);
	--pf-c-card--child--PaddingLeft: var(--pf-c-card--m-compact--child--PaddingLeft);
	--pf-c-card--c-divider--child--PaddingTop: var(--pf-c-card--m-compact--c-divider--child--PaddingTop);
	--pf-c-card__title--not--last-child--PaddingBottom: var(--pf-c-card--m-compact__title--not--last-child--PaddingBottom);
}

:host([size="large"]) {
	--pf-c-card__title--FontSize: var(--pf-c-card--m-display-lg__title--FontSize);
	--pf-c-card--first-child--PaddingTop: var(--pf-c-card--m-display-lg--first-child--PaddingTop);
	--pf-c-card--child--PaddingRight: var(--pf-c-card--m-display-lg--child--PaddingRight);
	--pf-c-card--child--PaddingBottom: var(--pf-c-card--m-display-lg--child--PaddingBottom);
	--pf-c-card--child--PaddingLeft: var(--pf-c-card--m-display-lg--child--PaddingLeft);
	--pf-c-card--c-divider--child--PaddingTop: var(--pf-c-card--m-display-lg--c-divider--child--PaddingTop);
	--pf-c-card__title--not--last-child--PaddingBottom: var(--pf-c-card--m-display-lg__title--not--last-child--PaddingBottom);
}

:host([flat]) {
  --pf-c-card--BoxShadow: none;
  border: var(--pf-c-card--m-flat--BorderWidth) solid var(--pf-c-card--m-flat--BorderColor);
}

:host([plain]) {
  --pf-c-card--BoxShadow: var(--pf-c-card--m-plain--BoxShadow);
  --pf-c-card--BackgroundColor: var(--pf-c-card--m-plain--BackgroundColor);
}

:host([rounded]) {
  border-radius: var(--pf-c-card--m-rounded--BorderRadius);
}

:host([full-height]) {
  height: var(--pf-c-card--m-full-height--Height);
  --pf-c-card__body--FullHeight--Flex: 1 1 auto;
}

`;
export default styles;

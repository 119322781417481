import {css} from 'lit';
export const styles = css`:host {
  font-family: var(--pf-global--FontFamily--sans-serif, "RedHatTextUpdated", "Overpass", overpass, helvetica, arial, sans-serif);
  font-size: var(--pf-global--FontSize--md, 16px);
  font-weight: var(--pf-global--FontWeight--normal, 400);
	color: var(--pf-global--Color--100, #151515);
  --_pf-option-checkboxes-display: none;
  --_pf-option-svg-display: block;
	--pf-c-select__toggle--PaddingTop: var(--pf-global--spacer--form-element, 0.375rem);
	--pf-c-select__toggle--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle--PaddingBottom: var(--pf-global--spacer--form-element, 0.375rem);
	--pf-c-select__toggle--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle--MinWidth: var(--pf-global--target-size--MinWidth, 44px);
	--pf-c-select__toggle--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-select__toggle--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-select__toggle--LineHeight: var(--pf-global--LineHeight--md, 1.5);
	--pf-c-select__toggle--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-select__toggle--before--BorderTopWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-select__toggle--before--BorderRightWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-select__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-select__toggle--before--BorderLeftWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-select__toggle--before--BorderWidth: initial;
	--pf-c-select__toggle--before--BorderTopColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-select__toggle--before--BorderRightColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-select__toggle--before--BorderBottomColor: var(--pf-global--BorderColor--200, #8a8d90);
	--pf-c-select__toggle--before--BorderLeftColor: var(--pf-global--BorderColor--300, #f0f0f0);
	--pf-c-select__toggle--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__toggle--hover--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-select__toggle--focus--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-select__toggle--focus--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-select__toggle--active--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-select__toggle--active--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--active-color--100, #06c);
	--pf-c-select__toggle--m-expanded--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-select__toggle--disabled--BackgroundColor: var(--pf-global--disabled-color--300, #f0f0f0);
	--pf-c-select__toggle--m-plain--before--BorderColor: transparent;
	--pf-c-select__toggle--m-placeholder--Color: transparent;
	--pf-c-select--m-invalid__toggle--before--BorderBottomColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-select--m-invalid__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-select--m-invalid__toggle--hover--before--BorderBottomColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-select--m-invalid__toggle--focus--before--BorderBottomColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-select--m-invalid__toggle--active--before--BorderBottomColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-select--m-invalid__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-select--m-invalid__toggle-status-icon--Color: var(--pf-global--danger-color--100, #c9190b);
	--pf-c-select--m-success__toggle--before--BorderBottomColor: var(--pf-global--success-color--100, #3e8635);
	--pf-c-select--m-success__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-select--m-success__toggle--hover--before--BorderBottomColor: var(--pf-global--success-color--100, #3e8635);
	--pf-c-select--m-success__toggle--focus--before--BorderBottomColor: var(--pf-global--success-color--100, #3e8635);
	--pf-c-select--m-success__toggle--active--before--BorderBottomColor: var(--pf-global--success-color--100, #3e8635);
	--pf-c-select--m-success__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--success-color--100, #3e8635);
	--pf-c-select--m-success__toggle-status-icon--Color: var(--pf-global--success-color--100, #3e8635);
	--pf-c-select--m-warning__toggle--before--BorderBottomColor: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-select--m-warning__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md, 2px);
	--pf-c-select--m-warning__toggle--hover--before--BorderBottomColor: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-select--m-warning__toggle--focus--before--BorderBottomColor: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-select--m-warning__toggle--active--before--BorderBottomColor: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-select--m-warning__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-select--m-warning__toggle-status-icon--Color: var(--pf-global--warning-color--100, #f0ab00);
	--pf-c-select__toggle-wrapper--not-last-child--MarginRight: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-select__toggle-wrapper--MaxWidth: calc(100% - var(--pf-global--spacer--lg, 1.5rem));
	--pf-c-select__toggle-wrapper--c-chip-group--MarginTop: 0.3125rem;
	--pf-c-select__toggle-wrapper--c-chip-group--MarginBottom: 0.3125rem;
	--pf-c-select__toggle-typeahead--FlexBasis: 10em;
	--pf-c-select__toggle-typeahead--BackgroundColor: transparent;
	--pf-c-select__toggle-typeahead--BorderTop: var(--pf-global--BorderWidth--sm, 1px) solid transparent;
	--pf-c-select__toggle-typeahead--BorderRight: none;
	--pf-c-select__toggle-typeahead--BorderLeft: none;
	--pf-c-select__toggle-typeahead--MinWidth: 7.5rem;
	--pf-c-select__toggle-typeahead--focus--PaddingBottom: calc(var(--pf-global--spacer--form-element, 0.375rem) - var(--pf-global--BorderWidth--md));
	--pf-c-select__toggle--m-placeholder__toggle-text--Color: var(--pf-global--Color--dark-200, #6a6e73);
	--pf-c-select__toggle-icon--toggle-text--MarginLeft: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-select__toggle-badge--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle-status-icon--MarginLeft: var(--pf-global--spacer--xs, 0.25rem);
	--pf-c-select__toggle-status-icon--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__toggle-arrow--MarginLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__toggle-arrow--MarginRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle-arrow--with-clear--MarginLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle-arrow--m-top--m-expanded__toggle-arrow--Rotate: 180deg;
	--pf-c-select--m-plain__toggle-arrow--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-select--m-plain--hover__toggle-arrow--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__toggle-clear--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle-clear--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__toggle-clear--toggle-button--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__toggle-button--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__menu--BackgroundColor: var(--pf-global--BackgroundColor--light-100, #fff);
	--pf-c-select__menu--BoxShadow: var(--pf-global--BoxShadow--md, 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06));
	--pf-c-select__menu--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu--Top: calc(100% + var(--pf-global--spacer--xs, 0.25rem));
	--pf-c-select__menu--ZIndex: var(--pf-global--ZIndex--sm, 200);
	--pf-c-select__menu--Width: auto;
	--pf-c-select__menu--MinWidth: 100%;
	--pf-c-select__menu--m-top--TranslateY: calc(-100% - var(--pf-global--spacer--xs, 0.25rem));
	--pf-c-select__list-item--m-loading--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu-item--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu-item--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-item--m-selected--PaddingRight: var(--pf-global--spacer--2xl, 3rem);
	--pf-c-select__menu-item--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu-item--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-item--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-select__menu-item--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-select__menu-item--LineHeight: var(--pf-global--LineHeight--md, 1.5);
	--pf-c-select__menu-item--Color: var(--pf-global--Color--dark-100, #151515);
	--pf-c-select__menu-item--disabled--Color: var(--pf-global--Color--dark-200, #6a6e73);
	--pf-c-select__menu-item--Width: 100%;
	--pf-c-select__menu-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300, #f0f0f0);
	--pf-c-select__menu-item--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300, #f0f0f0);
	--pf-c-select__menu-item--disabled--BackgroundColor: transparent;
	--pf-c-select__menu-item--m-link--Width: auto;
	--pf-c-select__menu-item--m-link--hover--BackgroundColor: transparent;
	--pf-c-select__menu-item--m-link--focus--BackgroundColor: transparent;
	--pf-c-select__menu-item--m-action--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-select__menu-item--m-action--hover--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__menu-item--m-action--focus--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__menu-item--m-action--disabled--Color: var(--pf-global--disabled-color--200, #d2d2d2);
	--pf-c-select__menu-item--m-action--Width: auto;
	--pf-c-select__menu-item--m-action--FontSize: var(--pf-global--icon--FontSize--sm, 0.625rem);
	--pf-c-select__menu-item--m-action--hover--BackgroundColor: transparent;
	--pf-c-select__menu-item--m-action--focus--BackgroundColor: transparent;
	--pf-c-select__menu-item--hover__menu-item--m-action--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-select__menu-item--m-favorite-action--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-select__menu-item--m-favorite-action--hover--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__menu-item--m-favorite-action--focus--Color: var(--pf-global--Color--100, #151515);
	--pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--Color: var(--pf-global--palette--gold-400, #f0ab00);
	--pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--hover--Color: var(--pf-global--palette--gold-500, #c58c00);
	--pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--focus--Color: var(--pf-global--palette--gold-500, #c58c00);
	--pf-c-select__menu-item--m-load--Color: var(--pf-global--link--Color, #06c);
	--pf-c-select__menu-item-icon--Color: var(--pf-global--active-color--100, #06c);
	--pf-c-select__menu-item-icon--FontSize: var(--pf-global--icon--FontSize--sm, 0.625rem);
	--pf-c-select__menu-item-icon--Right: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-item-icon--Top: 50%;
	--pf-c-select__menu-item-icon--TranslateY: -50%;
	--pf-c-select__menu-item-action-icon--MinHeight: calc(var(--pf-c-select__menu-item--FontSize) * var(--pf-c-select__menu-item--LineHeight));
	--pf-c-select__menu-item--match--FontWeight: var(--pf-global--FontWeight--bold, 700);
	--pf-c-select__menu-search--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu-search--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
	--pf-c-select__menu-search--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-search--PaddingLeft: var(--pf-c-select__menu-item--PaddingLeft);
	--pf-c-select__menu-group--menu-group--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu-group-title--PaddingTop: var(--pf-c-select__menu-item--PaddingTop);
	--pf-c-select__menu-group-title--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
	--pf-c-select__menu-group-title--PaddingBottom: var(--pf-c-select__menu-item--PaddingBottom);
	--pf-c-select__menu-group-title--PaddingLeft: var(--pf-c-select__menu-item--PaddingLeft);
	--pf-c-select__menu-group-title--FontSize: var(--pf-global--FontSize--xs, 0.75rem);
	--pf-c-select__menu-group-title--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-select__menu-group-title--Color: var(--pf-global--Color--dark-200, #6a6e73);
	--pf-c-select__menu-item-count--MarginLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-item-count--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-select__menu-item-count--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-select__menu-item--disabled__menu-item-count--Color: var(--pf-global--Color--dark-200, #6a6e73);
	--pf-c-select__menu-item-description--FontSize: var(--pf-global--FontSize--xs, 0.75rem);
	--pf-c-select__menu-item-description--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-select__menu-item-description--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
	--pf-c-select__menu-item-main--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
	--pf-c-select__menu-item--m-selected__menu-item-main--PaddingRight: var(--pf-c-select__menu-item--m-selected--PaddingRight);
	--pf-c-select__menu-footer--BoxShadow: var(--pf-global--BoxShadow--sm-top, 0 -0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16));
	--pf-c-select__menu-footer--PaddingTop: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-footer--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-footer--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-footer--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-select__menu-footer--MarginTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select__menu-footer--MarginBottom: calc(var(--pf-global--spacer--sm, 0.5rem) * -1);
	--pf-c-select-menu--c-divider--MarginTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-select-menu--c-divider--MarginBottom: var(--pf-global--spacer--sm, 0.5rem);
}

:host, #outer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

:host([hidden]),
*[hidden] {
  display: none !important;
}

:host([disabled]) {
  pointer-events: none !important;
}

#outer.disabled {
  color: var(--pf-global--Color--dark-200, #6a6e73) !important;
}

#outer {
  position: relative;
}

/* TODO(bennyp): see if we can get rid of this wrapping node, for perf reasons */
#listbox-container {
  display: inline-flex;
  border: 1px solid var(--pf-global--BorderColor--100, #d2d2d2);
  position: absolute;
  background-color: var(--pf-theme--color--surface--lightest, #fff) !important;
  opacity: 0;
  --_active-descendant-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important
}

#outer.expanded #listbox-container {
  opacity: 1;
  z-index: 9999 !important;
}

#listbox {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

#listbox slot.disabled {
  color: var(--pf-c-list__item-icon--Color, #6a6e73) !important;
  background-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
  border-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
  pointer-events: none;
  cursor: not-allowed;;
  --_active-descendant-color: transparent;
  --_svg-color: var(--pf-c-list__item-icon--Color, #6a6e73) !important;
}


#toggle {
  background-color: var(--pf-theme--color--surface--lightest, #fff) !important;
}

#toggle,
#toggle-button,
#toggle-input {
  display: flex;
  align-items: center;
  font-family: var(--pf-global--FontFamily--sans-serif, "RedHatTextUpdated", "Overpass", overpass, helvetica, arial, sans-serif);
  font-size: var(--pf-global--FontSize--md, 16px);
  font-weight: var(--pf-global--FontWeight--normal, 400);
  line-height: 1.6;
}

#toggle {
  border: 1px solid var(--pf-global--BorderColor--100, #d2d2d2);
  border-bottom-color: var(--pf-theme--color--text, #151515);
  justify-content: space-between;
}

.expanded #toggle {
  border-bottom-width: 2px;
  border-bottom-color: var(--pf-theme--color--accent, #0066cc);
}

.disabled #toggle {
  color: var(--pf-global--Color--dark-200, #6a6e73) !important;
  background-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
  border-color: var(--pf-theme--color--surface--lighter, #f0f0f0) !important;
}

#toggle-input,
#toggle-button {
  background: transparent;
  border: none;
  text-align: left;
  border-radius: 0;
  flex: 1 0 auto;
  min-height: 44px;
  min-width: 44px;
}

#toggle-input {
  justify-content: space-between;
  padding: var(--pf-global--spacer--xs, 0.25rem) var(--pf-global--spacer--sm, 0.5rem);
}

.disabled #toggle-input {
  pointer-events: none;
}

#toggle-button {
  color: currentColor;
  background-color: transparent;
  justify-content: flex-end;
  padding: var(--pf-global--spacer--sm, 0.5rem);
}

#toggle-button:focus:before {
  border-bottom-color: var(--pf-c-select__toggle--focus--before--BorderBottomColor);
  border-bottom-width: var(--pf-c-select__toggle--focus--before--BorderBottomWidth);
}

#outer.typeahead #toggle-button {
  flex: 0 0 auto;
}

#toggle-badge {
  flex: 1 0 auto;
  margin-inline-start: 0.25em;
}

#toggle-text {
  flex: 1 1 auto;
}

#toggle-text.badge {
  flex: 0 1 auto;
}

pf-badge {
  padding: 0;
}

#toggle svg {
  width: 1em;
  height: 1em;
  flex: 0 0 auto;
  margin-inline-start: 1em;
}

#description {
  display: block;
}

#listbox.checkboxes {
  --_pf-option-checkboxes-display: inline;
  --_pf-option-svg-display: none;
}

::slotted(pf-option-group + hr) {
  display: none !important;
}

::slotted(hr:has(+ pf-option-group)) {
  display: none !important;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  block-size: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  inline-size: 1px;
}

::slotted(hr) {
  --pf-c-divider--BorderWidth--base: var(--pf-global--BorderWidth--sm, 1px);
  --pf-c-divider--BorderColor--base: var(--pf-c-divider--BackgroundColor);
  --pf-c-divider--Height: var(--pf-c-divider--BorderWidth--base);
  --pf-c-divider--BackgroundColor: var(--pf-global--BorderColor--100, #d2d2d2);
  --pf-c-divider--after--BackgroundColor: var(--pf-c-divider--BorderColor--base);
  --pf-c-divider--after--FlexBasis: 100%;
  --pf-c-divider--after--Inset: 0%;
  --pf-c-divider--m-vertical--after--FlexBasis: 100%;
  --pf-c-divider--m-horizontal--Display: flex;
  --pf-c-divider--m-horizontal--FlexDirection: row;
  --pf-c-divider--m-horizontal--after--Height: var(--pf-c-divider--Height);
  --pf-c-divider--m-horizontal--after--Width: auto;
  --pf-c-divider--m-vertical--Display: inline-flex;
  --pf-c-divider--m-vertical--FlexDirection: column;
  --pf-c-divider--m-vertical--after--Height: auto;
  --pf-c-divider--m-vertical--after--Width: var(--pf-c-divider--BorderWidth--base);
  --pf-hidden-visible--visible--Display: var(--pf-c-divider--Display);
  --pf-c-divider--Display: var(--pf-c-divider--m-horizontal--Display);
  --pf-c-divider--FlexDirection: var(--pf-c-divider--m-horizontal--FlexDirection);
  --pf-c-divider--after--Width: var(--pf-c-divider--m-horizontal--after--Width);
  --pf-c-divider--after--Height: var(--pf-c-divider--m-horizontal--after--Height);
  display: var(--pf-c-divider--Display, flex);
	flex-direction: var(--pf-c-divider--FlexDirection);
	border: 0;
  width: 100%;
  margin-top: var(--pf-c-select-menu--c-divider--MarginTop);
  margin-bottom: var(--pf-c-select-menu--c-divider--MarginBottom);
}

::slotted(hr)::after {
  content: '';
  width: var(--pf-c-divider--after--Width, 100%) !important;
  height: var(--pf-c-divider--after--Height, 1px);
  background-color: var(--pf-c-divider--after--BackgroundColor);
  flex: 1 0 100%;
}
`;
export default styles;

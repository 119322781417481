import {css} from 'lit';
export const styles = css`:host {
  display: none;
  position: relative;
  overflow: hidden;
  will-change: height;
  color: var(--pf-global--Color--100, #151515);
  background-color:
    var(
      --pf-c-accordion--BackgroundColor,
      var(--pf-global--BackgroundColor--light-100, #ffffff)
    );
}

.body {
  padding:
    var(--pf-c-accordion__panel-body--PaddingTop, var(--pf-global--spacer--sm, 0.5rem))
    var(--pf-c-accordion__panel-body--PaddingRight, var(--pf-global--spacer--md, 1rem))
    var(--pf-c-accordion__panel-body--PaddingBottom, var(--pf-global--spacer--sm, 0.5rem))
    var(--pf-c-accordion__panel-body--PaddingLeft, var(--pf-global--spacer--md, 1rem));
}

.body:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--pf-c-accordion__panel-body--before--Width, var(--pf-global--BorderWidth--lg, 3px));
  background-color: var(--pf-c-accordion__panel-body--before--BackgroundColor, transparent);
}

:host([large]) {
  --pf-c-accordion__panel-body--PaddingTop:
    var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingTop, 0);
  --pf-c-accordion__panel-body--PaddingRight:
    var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingRight, 1rem);
  --pf-c-accordion__panel-body--PaddingBottom:
    var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingBottom, 1.5rem);
  --pf-c-accordion__panel-body--PaddingLeft:
    var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingLeft, 1.5rem);
  --pf-c-accordion__panel--FontSize:
    var(--pf-c-accordion--m-display-lg__expanded-content--FontSize, 1rem);
  --pf-c-accordion__panel--Color:
    var(--pf-c-accordion--m-display-lg__expanded-content--Color, #151515);
}

:host([large]) .body:last-child {
  --pf-c-accordion__panel-body--PaddingBottom:
    var(--pf-c-accordion--m-display-lg__expanded-content-body--last-child--PaddingBottom, 1.5rem);
}

.content {
  color: var(--pf-c-accordion__panel--Color, var(--pf-global--Color--dark-200, #6a6e73));
  font-size: var(--pf-c-accordion__panel--FontSize, var(--pf-global--FontSize--sm, 0.875rem));
}

:host([fixed]) {
  overflow-y: auto;
  max-height: var(--pf-c-accordion__panel--m-fixed--MaxHeight, 9.375rem);
}

:host([expanded]) {
  display: block;
  position: relative;
}

.content[expanded],
:host([expanded]) .content {
  --pf-c-accordion__panel-body--before--BackgroundColor:
    var(
      --pf-c-accordion__panel--content-body--before--BackgroundColor,
      var(--pf-global--primary-color--100, #0066cc));
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  --pf-c-table--cell--PaddingTop: var(--pf-c-table--tbody--cell--PaddingTop);
  --pf-c-table--cell--PaddingBottom: var(--pf-c-table--tbody--cell--PaddingBottom);
  display: grid;
}

@media (max-width: 768px) {
  :host {
    position: relative;
  }
  
  :host:first-of-type {
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
  }
}

`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  --pf-c-chip--PaddingTop: var(--pf-global--spacer--xs, 0.25rem);
  --pf-c-chip--PaddingRight: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-chip--PaddingBottom: var(--pf-global--spacer--xs, 0.25rem);
  --pf-c-chip--PaddingLeft: var(--pf-global--spacer--sm, 0.5rem);
  --pf-c-chip--BackgroundColor: var(--pf-global--Color--light-100, #fff);
  --pf-c-chip--BorderRadius: var(--pf-global--BorderRadius--sm, 3px);
  --pf-c-chip--before--BorderColor: var(--pf-global--BorderColor--300, #f0f0f0);
  --pf-c-chip--before--BorderWidth: var(--pf-global--BorderWidth--sm, 1px);
  --pf-c-chip--before--BorderRadius: var(--pf-c-chip--BorderRadius);
  --pf-c-chip--m-overflow__text--Color: var(--pf-global--primary-color--100, #06c);
  --pf-c-chip--m-draggable--BackgroundColor: var(--pf-global--BackgroundColor--200, #f0f0f0);
  --pf-c-chip--m-draggable--BoxShadow: var(--pf-global--BoxShadow--sm, 0 0.0625rem 0.125rem 0 rgba(3, 3, 3, 0.12), 0 0 0.125rem 0 rgba(3, 3, 3, 0.06));
  --pf-c-chip--m-draggable__icon--FontSize: var(--pf-global--icon--FontSize--sm, 0.625rem);
  --pf-c-chip__text--FontSize: var(--pf-global--FontSize--xs, 0.75rem);
  --pf-c-chip__text--Color: var(--pf-global--Color--100, #151515);
  --pf-c-chip__text--MaxWidth: 16ch;
  --pf-c-chip__icon--MarginLeft: var(--pf-global--spacer--sm, 0.5rem);
  color: var(--pf-global--Color--100, #151515);
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  list-style: none;
  background-color: var(--pf-c-chip--BackgroundColor);
  border-radius: var(--pf-c-chip--BorderRadius);
  padding:
	  var(--pf-c-chip--PaddingTop)
	  var(--pf-c-chip--PaddingRight)
	  var(--pf-c-chip--PaddingBottom)
	  var(--pf-c-chip--PaddingLeft);
}

[hidden],
:host([hidden]) {
  display: none !important;
}

div#outer {
  display: contents;
}

#outer:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: var(--pf-c-chip--before--BorderWidth) solid var(--pf-c-chip--before--BorderColor);
  border-radius: var(--pf-c-chip--before--BorderRadius);
}

span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  max-width: var(--pf-c-chip__text--MaxWidth);
  font-size: var(--pf-c-chip__text--FontSize);
  color: var(--pf-c-chip__text--Color);
}

:host([readonly]) span {
  color: var(--pf-global--Color--200, #6a6e73);
}

/* OVERFLOW */

button#outer {
  display: flex;
  background: none;
  border: 1px solid transparent;
}

button#outer span {
  color: var(--pf-c-chip--m-overflow__text--Color);
}

/* CLOSE */

#close-button {
  --pf-icon--size: 12px;
  --pf-c-button--PaddingTop: var(--pf-c-chip__c-button--PaddingTop);
  --pf-c-button--PaddingRight: var(--pf-c-chip__c-button--PaddingRight);
  --pf-c-button--PaddingBottom: var(--pf-c-chip__c-button--PaddingBottom);
  --pf-c-button--PaddingLeft: var(--pf-c-chip__c-button--PaddingLeft);
  --pf-c-button--FontSize: var(--pf-c-chip__c-button--FontSize);
  margin-top: var(--pf-c-chip__c-button--MarginTop);
  margin-right: var(--pf-c-chip__c-button--MarginRight);
  margin-bottom: var(--pf-c-chip__c-button--MarginBottom);
  inset-block-start: 0.125em;
}

::slotted(pf-badge) {
  font-size: var(--pf-global--FontSize--xs, 12px);
  margin: 0 0 0 var(--pf-global--spacer--xs, 0.25rem) !important;
  min-width: unset;
}
`;
export default styles;

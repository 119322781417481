import {css} from 'lit';
export const styles = css`:host {
  display: block;
}

[part="tabs-container"] {
  position: relative;
  display: flex;
  overflow: hidden;
}

[part="tabs-container"]::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-style: solid;
}

:host button {
  opacity: 1;
}

:host button:nth-of-type(1) {
  margin-inline-end: 0;
  translate: 0 0;
}

:host button:nth-of-type(2) {
  margin-inline-start: 0;
  translate: 0 0;
}

[part="tabs"],
[part="panels"] {
  display: block;
}

[part="tabs"] {
  scrollbar-width: none;
  position: relative;
  max-width: 100%;
  overflow-x: auto;
}

[part="tabs-container"]::before,
[part="tabs"]::before,
button::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-style: solid;
}

[part="tabs"]::before,
button::before {
  top: 0;
}

button,
[part="tabs"]::before {
  border: 0;
}

button {
  flex: none;
  line-height: 1;
  opacity: 0;
}

button::before {
  border-block-start-width: 0;
}

button:nth-of-type(1) {
  translate: -100% 0;
}

button:nth-of-type(2) {
  translate: 100% 0;
}

button:disabled {
  pointer-events: none;
}

[part="tabs-container"] {
  width: var(--pf-c-tabs--Width, auto);
  padding-inline-end: var(--pf-c-tabs--inset, 0);
  padding-inline-start: var(--pf-c-tabs--inset, 0);
}

[part="tabs-container"]::before {
  border-color: var(--pf-c-tabs--before--BorderColor, var(--pf-global--BorderColor--100, #d2d2d2));
  border-block-start-width: var(--pf-c-tabs--before--BorderTopWidth, 0);
  border-inline-end-width: var(--pf-c-tabs--before--BorderRightWidth, 0);
  border-block-end-width:  var(--pf-c-tabs--before--BorderBottomWidth, var(--pf-c-tabs--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px)));
  border-inline-start-width: var(--pf-c-tabs--before--BorderLeftWidth, 0);
}

:host([box]) [part="tabs-container"] {
  --pf-c-tabs__link--BackgroundColor: var(--pf-c-tabs--m-box__link--BackgroundColor, var(--pf-global--BackgroundColor--200, #f0f0f0));
  --pf-c-tabs__link--disabled--BackgroundColor: var(--pf-c-tabs--m-box__link--disabled--BackgroundColor, var(--pf-global--disabled-color--200, #d2d2d2));
  --pf-c-tabs__link--before--BorderBottomWidth: var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-tabs__link--before--BorderRightWidth: var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-tabs__link--disabled--before--BorderRightWidth: var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-tabs__link--after--Top: 0;
  --pf-c-tabs__link--after--Bottom: auto;
}

:host([box]) ::slotted(pf-tab:last-of-type) {
  --pf-c-tabs__link--before--BorderRightWidth: 0;
}

:host([box]) button:nth-of-type(2)::before {
  left: calc(var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px)) * -1);
}

:host([box]) pf-tab[aria-selected="true"] + pf-tab {
  --pf-c-tabs__link--before--Left: 0;
}

:host([box="light"]) [part="tabs-container"] {
  --pf-c-tabs__link--BackgroundColor: var(--pf-c-tabs--m-color-scheme--light-300__link--BackgroundColor, transparent);
  --pf-c-tabs__item--m-current__link--BackgroundColor: var(--pf-c-tabs--m-color-scheme--light-300__item--m-current__link--BackgroundColor, var(--pf-global--BackgroundColor--light-300, #f0f0f0));
  --pf-c-tabs__link--disabled--BackgroundColor: var(--pf-c-tabs--m-color-scheme--light-300__link--disabled--BackgroundColor, var(--pf-global--palette--black-150, #f5f5f5));
}

:host([vertical]) [part="tabs-container"] {
  --pf-c-tabs--Width: var(--pf-c-tabs--m-vertical--Width, 100%);
  --pf-c-tabs--inset: var(--pf-c-tabs--m-vertical--inset, var(--pf-global--spacer--lg, 1.5rem));
  --pf-c-tabs--before--BorderBottomWidth: 0;  /* *override user setting* border bottom should always be 0 for vertical tabs */
  --pf-c-tabs__link--PaddingTop: var(--pf-c-tabs--m-vertical__link--PaddingTop, var(--pf-global--spacer--md, 1rem));
  --pf-c-tabs__link--PaddingBottom: var(--pf-c-tabs--m-vertical__link--PaddingBottom, var(--pf-global--spacer--md, 1rem));
  --pf-c-tabs__link--before--Left: 0;
  --pf-c-tabs__link--disabled--before--BorderBottomWidth: 0; /* *override user setting* border bottom for disabled should always be 0 for vertical tabs */
  --pf-c-tabs__link--disabled--before--BorderLeftWidth: var(--pf-c-tabs--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-tabs__link--after--Top: 0;
  --pf-c-tabs__link--after--Right: auto;

  display: inline-flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  overflow: visible;
}

:host([vertical]) [part="tabs"] {
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  max-width: var(--pf-c-tabs--m-vertical--MaxWidth, 15.625rem);
}

:host([vertical]) [part="tabs"]::before {
  position: absolute;
  right: auto;
  border-style: solid;
  border-color: var(--pf-c-tabs--m-vertical__list--before--BorderColor, var(--pf-c-tabs--before--BorderColor, var(--pf-global--BorderColor--100, #d2d2d2)));
  border-block-start-width: var(--pf-c-tabs--m-vertical__list--before--BorderTopWidth, 0);
  border-inline-end-width: var(--pf-c-tabs--m-vertical__list--before--BorderRightWidth, 0);
  border-block-end-width: var(--pf-c-tabs--m-vertical__list--before--BorderBottomWidth, 0);
  border-inline-start-width: var(--pf-c-tabs--m-vertical__list--before--BorderLeftWidth, var(--pf-c-tabs--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px)));
}

:host([vertical]) ::slotted(pf-tab:first-of-type) {
  margin-block-start: var(--pf-c-tabs--inset, 0);
}

:host([vertical]) ::slotted(pf-tab:last-of-type) {
  margin-block-end: var(--pf-c-tabs--inset, 0);
}

:host([box][vertical]) [part="tabs-container"] {
  --pf-c-tabs--inset: var(--pf-c-tabs--m-vertical--m-box--inset, var(--pf-global--spacer--xl, 2rem));
  --pf-c-tabs--m-vertical__list--before--BorderLeftWidth: 0; /* *override user setting* border left should be 0 for vertical box; */
  --pf-c-tabs--m-vertical__list--before--BorderRightWidth: var(--pf-c-tabs--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  --pf-c-tabs__link--disabled--before--BorderRightWidth: var(--pf-c-tabs--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  /* --pf-c-tabs__link--disabled--before--BorderBottomWidth: var(--pf-c-tabs--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px)); */
  --pf-c-tabs__link--disabled--before--BorderLeftWidth: 0; /* *override user setting* border left should be 0 for disabled ; */
}

:host([box][vertical]) [part="tabs"]::before {
  right: 0;
  left: auto;
}

:host([box][vertical]) ::slotted(pf-tab:last-of-type) {
  --pf-c-tabs__link--before--BorderBottomWidth: 0;
  --pf-c-tabs__link--before--BorderRightWidth: var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
}

:host([box][vertical]) ::slotted(pf-tab[aria-selected="true"]) {
  --pf-c-tabs__link--before--BorderRightColor: var(--pf-c-tabs__item--m-current__link--BackgroundColor, var(--pf-global--BackgroundColor--100, #ffffff));
  --pf-c-tabs__link--before--BorderBottomColor: var(--pf-c-tabs__link--before--border-color--base, var(--pf-global--BorderColor--100, #d2d2d2));
  --pf-c-tabs__link--before--BorderBottomWidth: var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
}

:host([box][vertical]) ::slotted(pf-tab[aria-selected="true"]:first-of-type) {
  --pf-c-tabs__link--before--BorderTopWidth: var(--pf-c-tabs__link--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
}

[part="tabs"] {
  display: var(--pf-c-tabs__list--Display, flex);
}

button {
  width: var(--pf-c-tabs__scroll-button--Width, var(--pf-global--spacer--2xl, 3rem));
  color: var(--pf-c-tabs__scroll-button--Color, var(--pf-global--Color--100, #151515));
  background-color: var(--pf-c-tabs__scroll-button--BackgroundColor, var(--pf-global--BackgroundColor--100, #ffffff));
  outline-offset: var(--pf-c-tabs__scroll-button--OutlineOffset, calc(-1 * var(--pf-global--spacer--xs, 0.25rem)));
  transition:
    margin var(--pf-c-tabs__scroll-button--TransitionDuration--margin, .125s),
    translate var(--pf-c-tabs__scroll-button--TransitionDuration--transform, .125s), opacity var(--pf-c-tabs__scroll-button--TransitionDuration--opacity, .125s);
  --pf-icon--size: 16px;
}

button:hover {
  --pf-c-tabs__scroll-button--Color: var(--pf-c-tabs__scroll-button--hover--Color, var(--pf-global--active-color--100, #06c));
}

button::before {
  border-color: var(--pf-c-tabs__scroll-button--before--BorderColor, var(--pf-c-tabs--before--BorderColor, var(--pf-global--BorderColor--100, #d2d2d2)));
  border-inline-end-width: var(--pf-c-tabs__scroll-button--before--BorderRightWidth, 0);
  border-block-end-width: var(--pf-c-tabs__scroll-button--before--BorderBottomWidth, var(--pf-c-tabs__scroll-button--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px)));
  border-inline-start-width: var(--pf-c-tabs__scroll-button--before--BorderLeftWidth, 0);
}

button:nth-of-type(1) {
  --pf-c-tabs__scroll-button--before--BorderRightWidth: var(--pf-c-tabs__scroll-button--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  margin-inline-end: calc(var(--pf-c-tabs__scroll-button--Width, var(--pf-global--spacer--2xl, 3rem)) * -1);
}

button:nth-of-type(2) {
  --pf-c-tabs__scroll-button--before--BorderLeftWidth: var(--pf-c-tabs__scroll-button--before--border-width--base, var(--pf-global--BorderWidth--sm, 1px));
  margin-inline-start: calc(var(--pf-c-tabs__scroll-button--Width, var(--pf-global--spacer--2xl, 3rem)) * -1);
}

button:disabled {
  --pf-c-tabs__scroll-button--Color: var(--pf-c-tabs__scroll-button--disabled--Color, var(--pf-global--disabled-color--200, #d2d2d2));
}

:host(:not[vertical]) [part="tabs-container"] {
  --pf-c-tabs--inset: 0;
  --pf-c-tabs--m-vertical--inset: 0;
  --pf-c-tabs--m-vertical--m-box--inset: 0;
}

:host([fill]) [part="tabs"] {
  flex-basis: 100%;
}

:host([fill]) ::slotted(pf-tab) {
  flex-grow: 1;
}

:host([fill]) ::slotted(pf-tab:first-of-type) {
  --pf-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth: 0;
}

:host([fill]) ::slotted(pf-tab:last-of-type) {
  --pf-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth: 0;
}

:host([border-bottom="false"]) [part="tabs-container"] {
  --pf-c-tabs--before--BorderBottomWidth: 0; /* *override user setting* when border-bottom is false border bottom styles should be 0; */
  --pf-c-tabs__link--before--BorderBottomWidth: 0;
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  display: inline;
}

time {
  text-decoration: var(--_timestamp-text-decoration, none);
  text-underline-offset: var(--_timestamp-text-underline-offset, initial);
}
`;
export default styles;

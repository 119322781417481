import {css} from 'lit';
export const styles = css`:host {
  display: block;
}

:host([hidden]),
*[hidden] {
  display: none !important;
}

:host([disabled]) {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

:host(:focus) #outer,
:host(:hover) #outer,
#outer.selected {
  background-color: #e0e0e0;
}

#outer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: var(--pf-global--spacer--sm, 0.5rem) var(--pf-global--spacer--md, 1rem);
  min-height: calc(44px - 2 * var(--pf-global--spacer--sm, 0.5rem));
  min-width: calc(44px - 2 * var(--pf-global--spacer--md, 1rem));
}

#outer.active {
  background-color: var(--_active-descendant-color, var(--pf-theme--color--surface--lighter, #f0f0f0));
}

:host([disabled]) #outer {
  color: var(--pf-global--Color--dark-200, #6a6e73) !important;
}

input[type="checkbox"] {
  margin-inline-end: 1em;
  display: var(--_pf-option-checkboxes-display, none);
  pointer-events: none;
  flex: 0 0 auto;
}

span {
  flex: 1 1 auto;
}

svg {
  font-size: var(--pf-c-select__menu-item-icon--FontSize, var(--pf-global--icon--FontSize--sm, 0.675rem));
  color: var(--_svg-color, var(--pf-theme--color--accent, #0066cc));
  width: 1em;
  height: 1em;
  margin-inline-start: 1em;
  text-align: right;
  flex: 0 0 auto;
  display: var(--_pf-option-svg-display, block);
}

#description {
  display: block;
  flex: 1 0 100%;
}

slot[name="description"] {
  font-size: var(--pf-global--FontSize--xs, 0.75rem);
  color: var(--pf-global--Color--dark-200, #6a6e73);
}

::slotted([slot="icon"]) {
  margin-inline-end: 0.5em;
}

`;
export default styles;

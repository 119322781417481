import {css} from 'lit';
export const styles = css`:host {
  background: var(--pf-c-dropdown__menu--BackgroundColor, #fff);
}

.disabled {
  --pf-c-dropdown__menu-item--Color: var(
    --pf-c-dropdown__menu-item--disabled--Color,
    var(--pf-global--Color--dark-200, #6a6e73)
  ) !important;
  --pf-c-dropdown__menu-item--BackgroundColor: var(
    --pf-c-dropdown__menu-item--disabled--BackgroundColor,
    transparent
  ) !important;
}

:host([hidden]),
[hidden] {
  display: none !important;
}

::slotted(hr) {
  margin: 0;
  border-color: var(--pf-c-divider--BackgroundColor, var(--pf-global--BorderColor--100, #d2d2d2));
  border-style: solid;
}

::slotted([role="separator"]:not(hr)) {
  width: 100%;
  height: 1px;
  background-color: var(--pf-c-divider--BackgroundColor, var(--pf-global--BorderColor--100, #d2d2d2));
  padding: 0px;
  margin: 0px;
  border: 0;
  display: block;
  pointer-events: none;
}

`;
export default styles;

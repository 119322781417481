import {css} from 'lit';
export const styles = css`:host {
  font-weight: bold;
  padding: 1rem;
  position: relative;
}

:host(:empty) {
  padding: 0;
}

.sortable {
  padding-inline-end: 1.5em;
}

.sortable slot {
  display: inline;
  margin-inline-end: 1.5em;
}

button {
  width: 100%;
  padding: var(--pf-c-table--cell--PaddingTop) var(--pf-c-table--cell--PaddingRight) var(--pf-c-table--cell--PaddingBottom) var(--pf-c-table--cell--PaddingLeft);
  font-size: inherit;
  font-weight: inherit;
  color: var(--pf-c-table__button--Color);
  text-align: left;
  white-space: inherit;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background-color: var(--pf-c-table__button--BackgroundColor);
  border: 0;
}

button::before {
  position: absolute;
  inset: 0;
  cursor: pointer;
  content: '';
}

button:hover {
  --pf-c-table__sort-indicator--Color: var(--pf-c-table__sort__button--hover__sort-indicator--Color);
  --pf-c-table__sort__button__text--Color: var(--pf-c-table__sort__button--hover__text--Color);
}

button:active {
  --pf-c-table__sort-indicator--Color: var(--pf-c-table__sort__button--active__sort-indicator--Color);
  --pf-c-table__sort__button__text--Color: var(--pf-c-table__sort__button--active__text--Color);
}

button:focus {
  --pf-c-table__sort-indicator--Color: var(--pf-c-table__sort__button--focus__sort-indicator--Color);
  --pf-c-table__sort__button__text--Color: var(--pf-c-table__sort__button--focus__text--Color);
}

button.sortable {
  --pf-c-table--cell--PaddingTop: var(--pf-c-table__sort__button--PaddingTop);
  --pf-c-table--cell--PaddingRight: var(--pf-c-table__sort__button--PaddingRight);
  --pf-c-table--cell--PaddingBottom: var(--pf-c-table__sort__button--PaddingBottom);
  --pf-c-table--cell--PaddingLeft: var(--pf-c-table__sort__button--PaddingLeft);

  display: flex;
  width: auto;
  margin-top: var(--pf-c-table__sort__button--MarginTop);
  margin-bottom: var(--pf-c-table__sort__button--MarginBottom);
  margin-left: var(--pf-c-table__sort__button--MarginLeft);
}

button.selected {
  --pf-c-table__sort-indicator--Color: var(--pf-c-table__sort--m-selected__sort-indicator--Color);
  --pf-c-table__sort__button__text--Color: var(--pf-c-table__sort--m-selected__button__text--Color);

  color: var(--pf-c-table__sort--m-selected__button--Color);
}

#sort-indicator {
  width: 0;
  margin-inline-start: -1em;
  overflow: visible;
  color: var(--pf-c-table__sort-indicator--Color);
}

.visually-hidden {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`/******************************
 *                            *
 *            ICON            *
 *                            *
 ******************************/

.hasIcon {
  gap: calc(2 * var(--pf-c-button__icon--m-start--MarginLeft,
    var(--pf-global--spacer--xs, 0.25rem)));
}

`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
	--pf-c-accordion--BackgroundColor: var(--pf-global--BackgroundColor--100, #fff);
	--pf-c-accordion__toggle--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-accordion__toggle--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion__toggle--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-accordion__toggle--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion__toggle--before--BackgroundColor: transparent;
	--pf-c-accordion__toggle--before--Top: 0;
	--pf-c-accordion__toggle--hover--BackgroundColor: var(--pf-global--BackgroundColor--200, #f0f0f0);
	--pf-c-accordion__toggle--focus--BackgroundColor: var(--pf-global--BackgroundColor--200, #f0f0f0);
	--pf-c-accordion__toggle--active--BackgroundColor: var(--pf-global--BackgroundColor--200, #f0f0f0);
	--pf-c-accordion__toggle--before--Width: var(--pf-global--BorderWidth--lg, 3px);
	--pf-c-accordion__toggle--m-expanded--before--BackgroundColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-accordion__toggle-text--MaxWidth: calc(100% - var(--pf-global--spacer--lg, 1.5rem));
	--pf-c-accordion__toggle--hover__toggle-text--Color: var(--pf-global--link--Color, #06c);
	--pf-c-accordion__toggle--active__toggle-text--Color: var(--pf-global--link--Color, #06c);
	--pf-c-accordion__toggle--active__toggle-text--FontWeight: var(--pf-global--FontWeight--semi-bold, 700);
	--pf-c-accordion__toggle--focus__toggle-text--Color: var(--pf-global--link--Color, #06c);
	--pf-c-accordion__toggle--focus__toggle-text--FontWeight: var(--pf-global--FontWeight--semi-bold, 700);
	--pf-c-accordion__toggle--m-expanded__toggle-text--Color: var(--pf-global--link--Color, #06c);
	--pf-c-accordion__toggle--m-expanded__toggle-text--FontWeight: var(--pf-global--FontWeight--semi-bold, 700);
	--pf-c-accordion__toggle-icon--Transition: .2s ease-in 0s;
	--pf-c-accordion__toggle--m-expanded__toggle-icon--Rotate: 90deg;
	--pf-c-accordion__expanded-content--Color: var(--pf-global--Color--200, #6a6e73);
	--pf-c-accordion__expanded-content--FontSize: var(--pf-global--FontSize--sm, 0.875rem);
	--pf-c-accordion__expanded-content--m-expanded__expanded-content-body--before--BackgroundColor: var(--pf-global--primary-color--100, #06c);
	--pf-c-accordion__expanded-content--m-fixed--MaxHeight: 9.375rem;
	--pf-c-accordion__expanded-content-body--PaddingTop: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-accordion__expanded-content-body--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion__expanded-content-body--PaddingBottom: var(--pf-global--spacer--sm, 0.5rem);
	--pf-c-accordion__expanded-content-body--PaddingLeft: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion__expanded-content-body--expanded-content-body--PaddingTop: 0;
	--pf-c-accordion__expanded-content-body--before--BackgroundColor: transparent;
	--pf-c-accordion__expanded-content-body--before--Width: var(--pf-global--BorderWidth--lg, 3px);
	--pf-c-accordion__expanded-content-body--before--Top: 0;
	--pf-c-accordion--m-display-lg__toggle--PaddingTop: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion--m-display-lg__toggle--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion--m-display-lg__toggle--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion--m-display-lg__toggle--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-accordion--m-display-lg__toggle--FontFamily: var(--pf-global--FontFamily--heading--sans-serif, "RedHatDisplay", "Overpass", overpass, helvetica, arial, sans-serif);
	--pf-c-accordion--m-display-lg__toggle--FontSize: var(--pf-global--FontSize--xl, 1.25rem);
	--pf-c-accordion--m-display-lg__toggle--hover__toggle-text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-accordion--m-display-lg__toggle--active__toggle-text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-accordion--m-display-lg__toggle--active__toggle-text--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-accordion--m-display-lg__toggle--focus__toggle-text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-accordion--m-display-lg__toggle--focus__toggle-text--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--Color: var(--pf-global--Color--100, #151515);
	--pf-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--FontWeight: var(--pf-global--FontWeight--normal, 400);
	--pf-c-accordion--m-display-lg__expanded-content--FontSize: var(--pf-global--FontSize--md, 1rem);
	--pf-c-accordion--m-display-lg__expanded-content--Color: var(--pf-global--Color--100, #151515);
	--pf-c-accordion--m-display-lg__expanded-content-body--PaddingTop: 0;
	--pf-c-accordion--m-display-lg__expanded-content-body--PaddingRight: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion--m-display-lg__expanded-content-body--PaddingBottom: var(--pf-global--spacer--md, 1rem);
	--pf-c-accordion--m-display-lg__expanded-content-body--last-child--PaddingBottom: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-accordion--m-display-lg__expanded-content-body--PaddingLeft: var(--pf-global--spacer--lg, 1.5rem);
	--pf-c-accordion--m-bordered--BorderTopWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-accordion--m-bordered--BorderTopColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-accordion--m-bordered__toggle--before--Top: calc(-1 * var(--pf-global--BorderWidth--sm, 1px));
	--pf-c-accordion--m-bordered__toggle--after--BorderColor: var(--pf-global--BorderColor--100, #d2d2d2);
	--pf-c-accordion--m-bordered__toggle--after--BorderTopWidth: 0;
	--pf-c-accordion--m-bordered__toggle--after--BorderBottomWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-accordion--m-bordered__expanded-content--m-expanded__expanded-content-body--last-child--after--BorderBottomWidth: var(--pf-global--BorderWidth--sm, 1px);
	--pf-c-accordion--m-bordered__expanded-content--m-expanded__expanded-content-body--last-child--after--BorderBottomColor: var(--pf-global--BorderColor--100, #d2d2d2);
	color: var(--pf-global--Color--100, #151515);
	background-color: var(--pf-c-accordion--BackgroundColor);
}

:host([bordered]) ::slotted(pf-accordion-header:first-child),
:host([large]) ::slotted(pf-accordion-header:first-child) {
  display: block;
  border-top: 1px solid var(--accordion__bordered--Color);
  border-bottom: 1px solid var(--accordion__bordered--Color);
}

:host([bordered]) ::slotted(pf-accordion-header:not(:first-child)),
:host([large]) ::slotted(pf-accordion-header:not(:first-child)) {
  display: block;
  border-bottom: 1px solid var(--accordion__bordered--Color);
}

:host([bordered]) ::slotted(pf-accordion-header:is([expanded])),
:host([large]) ::slotted(pf-accordion-header:is([expanded])) {
  display: block;
  border-bottom: 0;
}

:host([bordered]) ::slotted(pf-accordion-panel:is([expanded])),
:host([large]) ::slotted(pf-accordion-panel:is([expanded])) {
  display: block;
  border-bottom: 1px solid var(--accordion__bordered--Color);
}
`;
export default styles;

import {css} from 'lit';
export const styles = css`:host {
  --pf-c-table--cell--MinWidth: var(--pf-c-table--m-truncate--cell--MinWidth);
  --pf-c-table--cell--MaxWidth: var(--pf-c-table--m-truncate--cell--MaxWidth);
  --pf-c-table--cell--Overflow: hidden;
  --pf-c-table--cell--TextOverflow: ellipsis;
  --pf-c-table--cell--WhiteSpace: nowrap;
  --pf-c-table--cell--FontSize: var(--pf-c-table--thead--cell--FontSize, var(--pf-global--FontSize--sm, 0.875rem));
  --pf-c-table--cell--FontWeight: var(--pf-c-table--thead--cell--FontWeight, var(--pf-global--FontWeight--bold, 700));
  vertical-align: bottom;
  display: grid;
  visibility: visible;
}

@media (max-width: 768px) {
  :host {
    display: none;
    visibility: hidden;
  }
}`;
export default styles;

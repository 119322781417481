import {css} from 'lit';
export const styles = css`:host {
  display: flex;

  --_background-image: var(--pf-c-background-image--BackgroundImage);
}

#outer-container {
  display: contents;
}

#container {
  padding: 0;
  margin: 0;
  background-color: transparent;
}

#container::after {
  display: block;
  position: fixed;
  top:  0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--pf-c-background-image--BackgroundColor, var(--pf-global--BackgroundColor--dark-100, #151515));
  background-image: var(--_background-image);
  filter: var(--pf-c-background-image--Filter, url("#image_overlay"));
  background-repeat:  no-repeat;
  background-size: cover;
}

slot[name="filter"] {
  display: none;
}

slot[part="content"] {
  display: block;
  position: relative;
  z-index: 1;
  color: white;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #container::after {
    background-image: var(--pf-c-background-image--BackgroundImage-2x, var(--_background-image-2x, var(--_background-image)));
  }
}

@media screen and (min-width: 576px) {
  #container::after {
    background-image: var(--pf-c-background-image--BackgroundImage--sm, var(--_background-image-sm, var(--_background-image)));
  }
}

@media screen and (min-width: 576px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 576px) and (min-resolution: 192dpi) {
  #container::after {
    background-image: var(--pf-c-background-image--BackgroundImage--sm-2x, var(--_background-image-sm-2x, var(--_background-image)));
  }
}

@media screen and (min-width: 992px) {
  #container::after {
    background-image: var(--pf-c-background-image--BackgroundImage--lg, var(--_background-image-lg, var(--_background-image)));
  }
}
`;
export default styles;
